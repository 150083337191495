import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const ifElseStatementsCode = `
  // If-Else Statements in Java

  public class IfElseStatementsExample {
      public static void main(String[] args) {
          int number = 10;

          // If-Else statement
          if (number > 0) {
              System.out.println("Number is positive");
          } else {
              System.out.println("Number is zero or negative");
          }

          // If-Else-If ladder
          if (number > 0) {
              System.out.println("Number is positive");
          } else if (number < 0) {
              System.out.println("Number is negative");
          } else {
              System.out.println("Number is zero");
          }
      }
  }
`;

const Lesson2Subtopic3_1 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.3.1: If-Else Statements</h4>
            <p>
                If-Else statements are used to make decisions in Java programming. They allow you to execute certain code blocks based on conditions.
            </p>

            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {ifElseStatementsCode.trim()}
                </SyntaxHighlighter>

                <button className="copy-button" onClick={() => handleCopy(ifElseStatementsCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                This Java example demonstrates the use of If-Else and If-Else-If statements to check whether a number is positive, negative, or zero.
            </p>
            <QuestionProcessor context='Java If Else Statments'/>
        </div>
    );
};

export default Lesson2Subtopic3_1;
