/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef } from 'react';
// Import Lessons
import Lesson1_IntroductionToJava from './Lessons/Lesson1_IntroductionToJava';
import Lesson1_S1_HistoryOfJava from './Lessons/Lesson1_S1_HistoryOfJava';
import Lesson1_S2_SettingUpJava from './Lessons/Lesson1_S2_SettingUpJava';
import Lesson1_S3_HelloWorldYourFirstProgram from './Lessons/Lesson1_S3_HelloWorldYourFirstProgram';
import Lesson2_JavaBasics from './Lessons/Lesson2_JavaBasics';
import Lesson2_S1__VariblesAndDataTypes from './Lessons/Lesson2_S1__VariblesAndDataTypes';
import Lesson2_S2_Operators from './Lessons/Lesson2_S2_Operators';
import Lesson2_S3_1_IfElseStatments from './Lessons/Lesson2_S3_1_IfElseStatments';
import Lesson2_S3_2_SwitchStatments from './Lessons/Lesson2_S3_2_SwitchStatments';
import Lesson2_S3_ConditionalStatements from './Lessons/Lesson2_S3_ConditionalStatements';
import Lesson2_S4_1_ForLoop from './Lessons/Lesson2_S4_1_ForLoop';
import Lesson2_S4_2_WhileLoop from './Lessons/Lesson2_S4_2_WhileLoop';
import Lesson2_S4_Loops from './Lessons/Lesson2_S4_Loops';
import Lesson2_S5_ArraysAndLists from './Lessons/Lesson2_S5_ArraysAndLists';
import Lesson2_S5_1_Arrays from './Lessons/Lesson2_S5_1_Arrays';
import Lesson2_S5_2_ArrayList from './Lessons/Lesson2_S5_2_ArrayList';
import Lesson2_S5_3_LinkedList from './Lessons/Lesson2_S5_3_LinkedList';
import Lesson2_S5_4_OtherLists from './Lessons/Lesson2_S5_4_OtherLists';
import Lesson2_S6_IOStream from './Lessons/Lesson2_S6_IOStream';
import Lesson2_S7_ExceptionHandling from './Lessons/Lesson2_S7_ExceptionHandling';
import Lesson3_ObjectOrientedProgramming from './Lessons/Lesson3_ObjectOrientedProgramming';
import Lesson3_S1_ClassesAndObjects from './Lessons/Lesson3_S1_ClassesAndObjects';
import Lesson3_S2_Methods from './Lessons/Lesson3_S2_Methods';
import Lesson3_S3_1_Inheritance from './Lessons/Lesson3_S3_1_Inheritance';
import Lesson3_S3_2_Polymorphism from './Lessons/Lesson3_S3_2_Polymorphism';
import Lesson3_S3_InheritanceAndPolymorphism from './Lessons/Lesson3_S3_InheritanceAndPolymorphism';
import Lesson3_S4_EncapsulationAndAbstraction from './Lessons/Lesson3_S4_EncapsulationAndAbstraction';
import QuizPage from './Lessons/QuizPage';
import ReferenceMaterial from './Lessons/ReferenceMaterial';
import ChallangeExercise from './Lessons/ChallangeExercise';

function CourseContent({ lesson, subtopic }) {
    const coursePageRef = useRef(null);

    useEffect(() => {
        if (coursePageRef.current) {
            const topOffset = coursePageRef.current.getBoundingClientRect().top + window.scrollY - 60;
            window.scrollTo({ top: topOffset, behavior: 'smooth' });
        }
    }, [lesson, subtopic]); // Scroll to the course page whenever the lesson or subtopic changes

    let content;

    switch (lesson) {
        case 'Lesson 1':
            switch (subtopic) {
                case 'Subtopic 1':
                    content = <Lesson1_S1_HistoryOfJava />;
                    break;
                case 'Subtopic 2':
                    content = <Lesson1_S2_SettingUpJava />;
                    break;
                case 'Subtopic 3':
                    content = <Lesson1_S3_HelloWorldYourFirstProgram />;
                    break;
                default:
                    content = <Lesson1_IntroductionToJava />;
                    break;
            }
            break;
        case 'Lesson 2':
            switch (subtopic) {
                case 'Subtopic 1':
                    content = <Lesson2_S1__VariblesAndDataTypes />;
                    break;
                case 'Subtopic 1.1':
                    content = <QuizPage configLocation='./Quizes/Varibles and Data Types.json' />
                    break;
                case 'Subtopic 2':
                    content = <Lesson2_S2_Operators />;
                    break;
                case 'Subtopic 2.1':
                    content = <QuizPage configLocation='./Quizes/Operators.json' />
                    break;
                case 'Subtopic 3':
                    content = <Lesson2_S3_ConditionalStatements />;
                    break;
                case 'Subtopic 3.1':
                    content = <Lesson2_S3_1_IfElseStatments />;
                    break;
                case 'Subtopic 3.2':
                    content = <Lesson2_S3_2_SwitchStatments />;
                    break;
                case 'Subtopic 3.3':
                    content = <QuizPage configLocation='./Quizes/Conditional Statments.json' />
                    break;
                case 'Subtopic 4':
                    content = <Lesson2_S4_Loops />;
                    break;
                case 'Subtopic 4.1':
                    content = <Lesson2_S4_1_ForLoop />;
                    break;
                case 'Subtopic 4.2':
                    content = <Lesson2_S4_2_WhileLoop />;
                    break;
                case 'Subtopic 4.3':
                    content = <QuizPage configLocation='./Quizes/Loops.json' />
                    break;
                case 'Subtopic 5':
                    content = <Lesson2_S5_ArraysAndLists />
                    break;
                case 'Subtopic 5.1':
                    content = <Lesson2_S5_1_Arrays />
                    break;
                case 'Subtopic 5.2':
                    content = <Lesson2_S5_2_ArrayList />
                    break;
                case 'Subtopic 5.3':
                    content = <Lesson2_S5_3_LinkedList />
                    break;
                case 'Subtopic 5.4':
                    content = <Lesson2_S5_4_OtherLists />
                    break;
                case 'Subtopic 5.5':
                    content = <QuizPage configLocation='./Quizes/List and Arrays.json' />
                    break;
                case 'Subtopic 5.6':
                    content = <ChallangeExercise configLocation='./Quizes/Challange1.json' />
                    break;
                case 'Subtopic 6':
                    content = <Lesson2_S6_IOStream />
                    break;
                case 'Subtopic 6s.1':
                    content = <QuizPage configLocation='./Quizes/IOStream.json' />
                    break;
                case 'Subtopic 7':
                    content = <Lesson2_S7_ExceptionHandling />
                    break;
                case 'Subtopic 7.1':
                    content = <QuizPage configLocation='./Quizes/Exception Handling.json' />
                    break;
                default:
                    content = <Lesson2_JavaBasics />;
                    break;
            }
            break;
        case 'Lesson 3':
            switch (subtopic) {
                case 'Subtopic 1':
                    content = <Lesson3_S1_ClassesAndObjects />;
                    break;
                case 'Subtopic 1.1':
                    content = <QuizPage configLocation='./Quizes/Classes and Objects.json' />
                    break;
                case 'Subtopic 2':
                    content = <Lesson3_S2_Methods />;
                    break;
                case 'Subtopic 2.1':
                    content = <QuizPage configLocation='./Quizes/Methods.json' />
                    break;
                case 'Subtopic 3':
                    content = <Lesson3_S3_InheritanceAndPolymorphism />;
                    break;
                case 'Subtopic 3.1':
                    content = <Lesson3_S3_1_Inheritance />;
                    break;
                case 'Subtopic 3.2':
                    content = <Lesson3_S3_2_Polymorphism />;
                    break;
                case 'Subtopic 3.3':
                    content = <QuizPage configLocation='./Quizes/Inheritance and Polymorphism.json' />
                    break;
                case 'Subtopic 4':
                    content = <Lesson3_S4_EncapsulationAndAbstraction />;
                    break;
                case 'Subtopic 4.1':
                    content = <QuizPage configLocation='./Quizes/Exception Handling.json' />
                    break;
                case 'Subtopic 4.2':
                    content = <ChallangeExercise configLocation='./Quizes/Challange2.json' />
                    break;
                default:
                    content = <Lesson3_ObjectOrientedProgramming />;
                    break;
            }
            break;
        case 'Lesson 4':
            switch (subtopic) {
                case 'Subtopic 4':
                    content = <Lesson3_S4_EncapsulationAndAbstraction />;
                    break;
                default:
                    content = <ReferenceMaterial />;
                    break;
            }
            break;
        default:
            content = <Lesson1_IntroductionToJava />;
            break;
    }

    return (
        <div className="course-page" ref={coursePageRef}>
            {content}
        </div>
    );
}

export default CourseContent;
