
import './Aquarium.css';

const ActiveMeasures = () => {

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">Phase #3: Active Measures</h4>
            <p>
                <br></br>
                <strong>The folloing implementations are in progress and will be update as they are implemented.</strong>
                <br></br>
            </p>

            <p>
                <br></br>
                Now that I can see the data from these sensors and can make conclusions based on that data lets put the data to use.
                I am going to implement the following actions
            </p>
            <ul>
                <li>Allow for a authenicated user to raise the water level in the tank if it is under the floats level.</li>
                <ul>
                    <li>Note: This may not need authetication if programmed with the correct safegards. These include but are not limited to a psycial disconnect and max fill ammounts determined by adreno locally.</li>
                </ul>
                <li>Enable and disable lights in tank based on time of day, furthermore increase or decrease power of lamps based on ambient lighting to keep lighting consistant throuout day.</li>
                <li>Automaticly enable or disable bubble tubes based on oxygen level in tank.</li>
                <li>Automaticly enable water heater based on temature of water</li>
                <li>Send user reports of tank health with recomendations of steps to take such as replacing equipment or manually cleaning.</li>
                <li>Create a hydroponic filtering system where the plans help to remove nitrates from the water.</li>
                <ul>
                    <li>Insall additiional sensors to ouput of filtration systom to monitor effectiveness. Then use data analysis to adjust flow rates to optimize filtration and energy use.</li>
                    <li>While sensors detect the nitrates are under control lower pump rates during nights too reduce noise</li>
                </ul>
                <li>Setup Additional sensors to detect rabid loss of water level and trigger an automatic emergency lowering of tank water levels.</li>
            </ul>
        </div>
    );
};

export default ActiveMeasures;
