import React from 'react';
import './Lessons.css';
import QuestionProcessor from './QuestionProcesser';

const Lesson3_S3_InheritanceAndPolymorphism = () => {
    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">3.3: Inheritance and Polymorphism in Java</h2>

            <div>
                <h3>1. Inheritance</h3>
                <p>
                    Inheritance is a fundamental concept in object-oriented programming (OOP) that allows
                    one class to inherit the properties and behaviors (methods and fields) of another class.
                    The class being inherited from is called the superclass or parent class, and the class
                    inheriting from it is called the subclass or child class.
                </p>
                <p>
                    Inheritance promotes code reusability, supports the concept of hierarchical
                    classifications, and allows for the extension of existing classes without modifying them.
                </p>
            </div>

            <div>
                <h3>2. Polymorphism</h3>
                <p>
                    Polymorphism is the ability of an object to take on many forms. In Java, polymorphism
                    is achieved through method overloading and method overriding. Method overloading allows
                    a class to have more than one method having the same name if their parameter lists
                    are different. Method overriding means that a subclass provides a specific
                    implementation of a method that is already provided by its parent class.
                </p>
                <p>
                    Polymorphism in Java helps to perform a single action in different ways. It provides a
                    way to perform many operations in a single way.
                </p>
            </div>

            <div>
                <h3>3. Importance</h3>
                <p>
                    Understanding inheritance and polymorphism is crucial for building scalable and
                    maintainable Java applications. These concepts enable efficient code reuse, promote
                    extensibility, and allow for the implementation of complex object-oriented designs.
                </p>
                <p>
                    Inheritance and polymorphism facilitate the creation of hierarchies, improve code
                    structure, and enable developers to write flexible and modular code that can be adapted
                    to changing requirements.
                </p>
            </div>
            <QuestionProcessor context='Java inheritance and polymorphism'/>
        </div>
    );
}

export default Lesson3_S3_InheritanceAndPolymorphism;
