
import './MatchingActivity.css'; // Import the CSS file;
import './Lessons/Lessons.css'; // Assuming you have a shared styles file
import ModernizedMatchingActivity from './ModernizedMatchingActivity.js'
import FlashExample from '../../img/FlashProgram.gif'
const MatchingActivity = () => {

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">Flash Matching Activity</h2>
            <p>Adobe Flash was once an extremely popular engine for creating interactive and animated elements on webpages. It has since been deprecated and as a result, all these old creations can no longer be used. With this in mind, I decided to create a matching activity in Flash and then show how these apps can be recreated with modern web design.</p>
            <p>First, let's take a look at the original Flash example.</p>
            <p><i>Note: Adobe Flash stopped receiving security updates and was formally deprecated across all platforms on December 31, 2020. Due to security concerns of having this app live on this site, I have only included a recording of the app in action.</i></p>
            <img src={FlashExample} alt='Flash Matching Activity Example' />
            
            <h3>Recreating the Application</h3>
            <p>We could recreate the app as is, but instead, let's improve it. Here are the changes we will implement:</p>
            <ul>
                <li>Dynamically adjust the app based on the number of matches included in the activity.</li>
                <li>Allow for image to image matches, text to image, and text to text matches.</li>
                <li>The activity must be populated by an external config file; the user should only need to select the component and reference the config file.</li>
                <li>Modernize the app's style.</li>
                <li>Add quality of life features such as an automatic score and a clear incorrect choice indication.</li>
            </ul>

            <ModernizedMatchingActivity />
        </div>
    );
};

export default MatchingActivity;
