import React from 'react';
import './Aquarium.css';
import SensorDataDisplay from './SensorDataDisplay';
import Slideshow from '../../Slideshow'; // Adjust the import path as needed
import SensorImage1 from '../../../img/Sensor1.jpg';
import SensorImage2 from '../../../img/Sensor2.jpg';
import SensorImage3 from '../../../img/Sensor3.jpg';

const SimpleSensors = () => {
    const images = [SensorImage1, SensorImage2, SensorImage3]; // Array of images

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">Phase #1: Simple Sensors</h4>
            <p>
                For the first phase, I have installed several sensors on an Arduino Uno to verify the functionality and viability of this project.
            </p>
            <SensorDataDisplay />
            <p></p>
            <h5 className="subtopic-header">
                The following sensors were installed:
            </h5>
            <ul>
                <li>Float Switch (45mm Float Switch Stainless Steel Liquid Water Level Sensor)</li>
                <li>DHT22 (AM2302) Single Bus Digital Temperature and Humidity Sensor</li>
                <li>DS18B20 Waterproof Temperature Sensor</li>
                <li>Light Sensor (TSL2561 Luminosity Sensor)</li>
                <li>BH1750 Digital Light Intensity Sensor</li>
            </ul>
            <Slideshow images={images} interval={3000} /> {/* Pass images and interval */}
        </div>
    );
};

export default SimpleSensors;
