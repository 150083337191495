import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Java code example for Exception Handling
const exceptionHandlingCode = `
  // Exception Handling in Java

  public class ExceptionHandlingExample {
      public static void main(String[] args) {
          try {
              int[] numbers = {1, 2, 3};
              System.out.println(numbers[10]); // Accessing out-of-bound element
          } catch (ArrayIndexOutOfBoundsException e) {
              System.err.println("Index out of bounds: " + e.getMessage());
          } finally {
              System.out.println("Finally block executed.");
          }

          try {
              int result = divide(10, 0); // Division by zero
              System.out.println("Result: " + result);
          } catch (ArithmeticException e) {
              System.err.println("Arithmetic exception: " + e.getMessage());
          }
      }

      public static int divide(int a, int b) {
          return a / b;
      }
  }
`;

const Lesson2_S7_ExceptionHandling = () => {
    // Function to handle copying code to clipboard
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.7: Exception Handling</h4>
            <p>
                Exception handling in Java is a mechanism to handle runtime errors, ensuring that the normal flow of the program is not disrupted. It allows us to gracefully handle unexpected situations that may arise during program execution.
            </p>

            <div className="code-block">
                {/* SyntaxHighlighter component to display Java code */}
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {exceptionHandlingCode.trim()}
                </SyntaxHighlighter>
                {/* Button to copy code to clipboard */}
                <CopyToClipboard text={exceptionHandlingCode.trim()}>
                    <button className="copy-button" onClick={() => handleCopy(exceptionHandlingCode.trim())}>
                        Copy Code
                    </button>
                </CopyToClipboard>
            </div>

            <h3>Understanding Exception Handling</h3>
            <p>
                Exception handling allows us to handle errors such as division by zero, accessing out-of-bound array elements, or other unexpected situations that can occur during program execution.
            </p>

            <h3>When to Use Exception Handling</h3>
            <p>
                Use exception handling whenever there is a possibility of runtime errors that can disrupt the normal flow of your program. It's particularly useful for handling scenarios where input data may be incorrect or when resources are not available.
            </p>

            <h3>Key Concepts of Exception Handling</h3>
            <ul>
                <li><strong>try-catch-finally:</strong> The try block contains the code that may throw an exception. The catch block handles the exception if it occurs, and the finally block ensures that code is always executed, whether an exception is thrown or not.</li>
                <li><strong>Checked vs Unchecked Exceptions:</strong> Checked exceptions are checked at compile-time, while unchecked exceptions are not. Checked exceptions must be handled (caught or declared) in the code.</li>
                <li><strong>Common Exception Types:</strong> Includes ArithmeticException, NullPointerException, ArrayIndexOutOfBoundsException, etc.</li>
            </ul>
            {/* Component for processing questions related to the lesson */}
            <QuestionProcessor context='Java Exception Handling'/>
        </div>
    );
};

export default Lesson2_S7_ExceptionHandling;
