import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MovieSearch.css';

const MovieSearch = ({ onSelectMovie }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedMovies, setSelectedMovies] = useState([]);
    const [comparisonResults, setComparisonResults] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [comparing, setComparing] = useState(false);
    const resultsRef = useRef(null); // Ref for the search results container

    useEffect(() => {
        // Scroll to bottom of the search results container when results change
        if (resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [searchResults, comparisonResults]);

    const handleSearch = async () => {
        if (searchQuery.trim() === '') {
            setErrorMessage('Please enter a movie title.');
            return;
        }

        setLoading(true);
        setErrorMessage('');

        try {
            const response = await axios.get(`https://45.77.158.54:5000/api/search_movie`, {
                params: {
                    query: searchQuery
                }
            });
            setSearchResults(response.data.results);
        } catch (error) {
            console.error('Error searching for movies:', error);
            setErrorMessage('Error searching for movies. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        setSearchResults([]);
        setErrorMessage('');
    };

    const handleSelectMovie = (movie) => {
        if (selectedMovies.length >= 2) {
            setErrorMessage('You can select only up to 2 movies. Please delete at least one movie before selecting another.');
            return;
        }

        if (selectedMovies.some((m) => m.movie_id === movie.movie_id)) {
            setErrorMessage('This movie is already selected.');
            return;
        }

        const movieWithId = { ...movie, id: movie.movie_id };
        setSelectedMovies([...selectedMovies, movieWithId]);
        onSelectMovie(movieWithId);
        setSearchQuery('');
        setErrorMessage('');
    };

    const handleRemoveMovie = (movie) => {
        const updatedMovies = selectedMovies.filter((m) => m.movie_id !== movie.movie_id);
        setSelectedMovies(updatedMovies);
        setComparisonResults(null);
        setErrorMessage('');
    };

    const handleCompareMovies = async () => {
        if (selectedMovies.length !== 2) {
            setErrorMessage('Please select exactly 2 movies to compare.');
            return;
        }

        try {
            setComparing(true);
            setErrorMessage('');

            const response = await axios.get(`https://45.77.158.54:5000/api/query_path`, {
                params: {
                    start_movie_id: selectedMovies[0].title + "(" + selectedMovies[0].year + ")",
                    end_movie_id: selectedMovies[1].title + "(" + selectedMovies[1].year + ")"
                }
            });

            if (typeof response.data === 'string') {
                setComparisonResults([{ detail: response.data }]);
            } else if (Array.isArray(response.data)) {
                setComparisonResults(response.data);
            } else if (typeof response.data === 'object') {
                setComparisonResults([response.data]);
            } else {
                setErrorMessage('Invalid response data format.');
            }

        } catch (error) {
            console.error('Error comparing movies:', error);
            setErrorMessage('Error comparing movies. Please try again.');
        } finally {
            setComparing(false);
        }
    };

    const handleClearSelection = () => {
        setSelectedMovies([]);
        setComparisonResults(null);
        setErrorMessage('');
        setSearchQuery('');
    };


    const renderComparisonResults = () => {
        return (
            <div className="comparison-results">
                <h3>Comparison Results:</h3>
                {comparisonResults && comparisonResults.length > 0 && (
                    <div className="result-detail" dangerouslySetInnerHTML={{ __html: comparisonResults[0].detail }} />
                )}
            </div>
        );
    };

    return (
        <div className="movie-search">
            <h1>IMDb Movies 6 Degrees of Separation</h1>
            <div className="search-container">
                <input
                    id="default"
                    type="text"
                    placeholder="Search for a movie..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    disabled={loading || comparing}
                />
                <button className="search-button" onClick={handleSearch} disabled={loading}>
                    {loading ? 'Searching...' : 'Search'}
                </button>
                <button className="clear-search-button" onClick={handleClearSearch} disabled={loading}>
                    Clear
                </button>
            </div>
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}
            <div className="search-results" ref={resultsRef}>
                {searchResults.length > 0 && (
                    <h3>Search Results:</h3>
                )}
                {searchResults.length > 0 && (
                    <ul>
                        {searchResults.map((movie, index) => (
                            <li key={index}>
                                <div className="result-info">
                                    <div className="movie-details">
                                        <span className="movie-title">{movie.title}</span>{' '}
                                        <span>({movie.year})</span>
                                    </div>
                                    <button className="add-button" onClick={() => handleSelectMovie(movie)} disabled={selectedMovies.length >= 2 || selectedMovies.some((m) => m.movie_id === movie.movie_id)}>
                                        Add
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {selectedMovies.length > 0 && (
                <div className="selected-movies">
                    <h3>Selected Movies:</h3>
                    <div className="selected-movies-list">
                        {selectedMovies.length > 0 ? (
                            <ul>
                                {selectedMovies.map((movie, index) => (
                                    <li key={index} className="movie-card">
                                        <div className="movie-info">
                                            <span className="movie-title">{movie.title}</span>{' '}
                                            <span>({movie.year})</span>
                                        </div>
                                        <button className="remove-button" onClick={() => handleRemoveMovie(movie)}>
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No movies selected.</p>
                        )}
                    </div>
                    <div className="button-container">
                        <button className="compare-button" onClick={handleCompareMovies} disabled={comparing}>
                            {comparing ? 'Comparing...' : 'Compare Movies'}
                        </button>
                        <button className="clear-selection" onClick={handleClearSelection} disabled={comparing}>
                            Clear Selection
                        </button>
                    </div>
                </div>
            )}
            {comparisonResults && renderComparisonResults()}
        </div>
    );
};

export default MovieSearch;
