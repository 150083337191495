import React, { useState } from 'react';
import './Lessons.css'; // Assuming you have a shared styles file

import EclipseInstaller from '../../../img/EclipseInstaller.PNG'
import EclipseInstaller2 from '../../../img/EclipseInstaller2.PNG'
import EclipseInstaller3 from '../../../img/EclipseInstaller3.PNG'
import EclipseInstallPage from '../../../img/EclipseInstallPage.PNG'
import EclipseInstallPage2 from '../../../img/EclipseInstallPage2.PNG'

import QuestionProcessor from './QuestionProcesser';

const Slideshow = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    };

    return (
        <div className="slideshow-container">
            <div className="mySlides fade">
                <img src={images[currentSlide]} style={{ width: '100%' }} alt={`Slide ${currentSlide + 1}`} />
            </div>

            <a className="prev" onClick={prevSlide}>&#10094; Prev</a>
            <a className="next" onClick={nextSlide}>Next &#10095;</a>
        </div>
    );
};

const Lesson1Subtopic2 = () => {
    return (

        <div className="lesson-content">
            <h4 className="subtopic-header">1.2: Setting Up Java</h4>
            <p>
                This section will guide you through the steps to install Java and Eclipse Integrated Development Environment (IDE).
            </p>

            <h5 className="subtopic-header">Installing Java</h5>
            <p>
                To start programming in Java, you need to install the Java Development Kit (JDK), which includes the Java Runtime Environment (JRE) and other tools needed for developing Java applications.
            </p>
            <p>
                You can download the latest JDK from the <a href="https://www.oracle.com/java/technologies/javase-downloads.html" target="_blank" rel="noopener noreferrer">Oracle website</a>. Follow the installation instructions provided by Oracle for your operating system.
            </p>
            <p>
                
                Once installed, you can verify the installation by opening a command prompt (Windows) or terminal (macOS, Linux) and typing <code>java -version</code> and <code>javac -version</code>.
            </p>
            <h5 className="subtopic-header">Installing Eclipse IDE</h5>
            <p>
                Eclipse is a popular IDE used for Java development. It provides a comprehensive set of tools and features to help you write and debug Java programs.
            </p>
            <p>
                You can download Eclipse IDE for Java Developers from the <a href="https://www.eclipse.org/downloads/" target="_blank" rel="noopener noreferrer">Eclipse website</a>. Choose the appropriate installer for your operating system.
            </p>
            <p>
                Follow the installation instructions provided by Eclipse. Once installed, you can launch Eclipse and configure it for Java development.
            </p>
            <Slideshow images={[EclipseInstallPage, EclipseInstallPage2, EclipseInstaller, EclipseInstaller2, EclipseInstaller3]} />
            <QuestionProcessor context='Setting up Eclipse IDE and Java Development Kit'/>
        </div>
    );
};

export default Lesson1Subtopic2;
