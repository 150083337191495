// src/components/Examples/Lessons/Lesson3_S3_2_Polymorphism.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css';
import QuestionProcessor from './QuestionProcesser';

const Lesson3_S3_2_Polymorphism = () => {
    const codeString = `// Parent class
class Animal {
    public void sound() {
        System.out.println("This is an animal sound");
    }
}

// Child class 1
class Dog extends Animal {
    @Override
    public void sound() {
        System.out.println("Woof");
    }
}

// Child class 2
class Cat extends Animal {
    @Override
    public void sound() {
        System.out.println("Meow");
    }
}

public class PolymorphismExample {
    public static void main(String[] args) {
        Animal myDog = new Dog();
        Animal myCat = new Cat();

        myDog.sound();
        myCat.sound();
    }
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">3.3.2: Polymorphism</h2>
            <p>
                Polymorphism is one of the core concepts of object-oriented programming (OOP) that allows objects to be treated as instances of their parent class rather than their actual class. The most common use of polymorphism is when a parent class reference is used to refer to a child class object.
            </p>
            <h3>What is Polymorphism?</h3>
            <p>
                Polymorphism means "many shapes" and it allows one interface to be used for a general class of actions. The specific action is determined by the exact nature of the situation. In Java, polymorphism is achieved through method overriding and method overloading.
            </p>
            <h3>Why is Polymorphism Important?</h3>
            <ul>
                <li><b>Flexibility:</b> It allows for implementing clean and manageable code by enabling one interface to control access to a general class of actions.</li>
                <li><b>Reusability:</b> Methods can use objects of classes that share the same superclass, enhancing code reusability.</li>
                <li><b>Maintainability:</b> It makes code more manageable and maintainable as changes to a method in the superclass automatically apply to the subclass.</li>
            </ul>
            <h3>Example: Polymorphism in Java</h3>
            <p>Let's understand polymorphism through an example in Java:</p>
            <div className="code-block">
                <CopyToClipboard text={codeString}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {codeString}
                </SyntaxHighlighter>
            </div>
            <h3>Explanation of the Example</h3>
            <p>Here is a breakdown of the example code to understand how polymorphism works in practice:</p>
            <ul>
                <li><b>Animal Class:</b> This is the parent class with a method <code>sound()</code> that prints a general message.</li>
                <li><b>Dog Class:</b> This class extends <code>Animal</code> and overrides the <code>sound()</code> method to print "Woof".</li>
                <li><b>Cat Class:</b> This class extends <code>Animal</code> and overrides the <code>sound()</code> method to print "Meow".</li>
                <li><b>PolymorphismExample Class:</b> This class contains the <code>main</code> method where <code>Animal</code> references are used to refer to <code>Dog</code> and <code>Cat</code> objects. The overridden <code>sound()</code> method is called, demonstrating polymorphism. The output will be:
                    <pre>Woof
                        Meow</pre>
                </li>
            </ul>
            <h3>Benefits of Polymorphism</h3>
            <ul>
                <li><b>Improved Code Organization:</b> Polymorphism helps in organizing code in a more logical and hierarchical manner.</li>
                <li><b>Code Reusability:</b> By using polymorphism, the same method can be used on different objects, enhancing code reuse.</li>
                <li><b>Enhanced Maintainability:</b> Changes made in the superclass method automatically reflect in the subclass methods, making the code easier to maintain.</li>
            </ul>
            <QuestionProcessor context='Java polymorphism'/>
        </div>

    );
};

export default Lesson3_S3_2_Polymorphism;
