import React, { useState, useEffect } from 'react';
import matchingData from './matchingData.json'; // Import JSON file
import './ModernizedMatchingActivity.css'; // Ensure to include your custom CSS file

const ModernizedMatchingActivity = () => {
    const [selectedPairs, setSelectedPairs] = useState([]);
    const [score, setScore] = useState(0);
    const [shuffledData, setShuffledData] = useState([]);

    useEffect(() => {
        // Shuffle the matchingData array
        const shuffledArray = shuffleArray(matchingData);
        setShuffledData(shuffledArray);
    }, []);

    const handleClick = (id) => {
        const element = document.getElementById(id);

        // Check conditions to prevent selecting more than two tiles, completed tiles, and already selected tiles
        if (
            selectedPairs.length === 2 || // Prevent selecting more than two tiles
            element.classList.contains('matched') || // Prevent selecting completed tiles
            selectedPairs.includes(id) // Prevent selecting already selected tiles
        ) {
            return;
        }

        // Change tile color to indicate selection
        element.style.backgroundColor = 'lightblue';
        setSelectedPairs([...selectedPairs, id]);

        // Check for a match if two items are selected
        if (selectedPairs.length === 1) {
            const [first] = selectedPairs;
            const second = id;

            if (first.split('_')[0] === second.split('_')[0]) {
                // Successful pair
                setScore(score + 1);
                document.getElementById(first).classList.add('matched');
                document.getElementById(second).classList.add('matched');
                document.getElementById(first).style.backgroundColor = 'gray';
                document.getElementById(second).style.backgroundColor = 'gray';
                setSelectedPairs([]);
            } else {
                // Unsuccessful pair
                document.getElementById(first).style.backgroundColor = 'Tomato';
                document.getElementById(second).style.backgroundColor = 'Tomato';
                setTimeout(() => {
                    document.getElementById(first).style.backgroundColor = '';
                    document.getElementById(second).style.backgroundColor = '';
                    setSelectedPairs([]);
                }, 500);
            }
        }
    };

    return (
        <div className="matching-activity">
            <h3>Matching Activity</h3>
            <p>Select 2 tiles matching tiles at a time to complete the activity</p>
            <div className="tiles-container">
                {shuffledData.map((item, index) => (
                    <div
                        key={index}
                        id={`${item.id}_${item.data}`}
                        className="tile"
                        onClick={() => handleClick(`${item.id}_${item.data}`)}
                    >
                        {item.image ? <img src={item.image} alt={item.data} /> : item.data}
                    </div>
                ))}
            </div>

            <p>Score: {score}/{shuffledData.length / 2}</p>
        </div>
    );
};

function shuffleArray(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export default ModernizedMatchingActivity;
