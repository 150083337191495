// src/components/Examples/Lessons/ArraysAndLists.js

import React from 'react';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const ArraysAndLists = () => {

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.5: Arrays and Lists</h2>
            <p>
                Arrays and lists are fundamental data structures in Java that allow you to store and manipulate collections of elements. Each has its own advantages and use cases.
            </p>

            <h3>Arrays</h3>
            <p>
                Arrays are fixed-size data structures that store elements of the same type sequentially in memory. They provide fast access to elements based on their index.
            </p>
            <ul>
                <li>Use arrays when the size of the collection is known and fixed.</li>
                <li>Arrays use less memory compared to ArrayList and LinkedList.</li>
                <li>Example: <code>int[] numbers = {1, 2, 3, 4, 5};</code></li>
            </ul>

            <h3>ArrayList</h3>
            <p>
                ArrayList is a dynamic data structure that implements the List interface. It automatically resizes itself when elements are added or removed.
            </p>
            <ul>
                <li>Use ArrayList when you need dynamic resizing and fast access times.</li>
                <li>ArrayList is not synchronized, meaning it is not thread-safe.</li>
                <li>Example: <code>ArrayList&lt;String&gt; names = new ArrayList&lt;&gt;();</code></li>
            </ul>

            <h3>LinkedList</h3>
            <p>
                LinkedList is a doubly linked list implementation of the List interface. It provides efficient insertion and deletion operations.
            </p>
            <ul>
                <li>Use LinkedList when you frequently add or remove elements from the middle of the list.</li>
                <li>LinkedList uses more memory compared to ArrayList due to the overhead of maintaining nodes.</li>
                <li>Example: <code>LinkedList&lt;String&gt; names = new LinkedList&lt;&gt;();</code></li>
            </ul>

            <h3>Other List Implementations</h3>
            <p>
                In addition to ArrayList and LinkedList, Java provides other list-like structures:
            </p>
            <ul>
                <li><strong>Vector:</strong> Similar to ArrayList but synchronized (thread-safe).</li>
                <li><strong>Stack:</strong> A subclass of Vector, implements a last-in, first-out (LIFO) stack of elements.</li>
                <li><strong>Queue:</strong> Interface representing a FIFO (First-In-First-Out) queue.</li>
                <li><strong>PriorityQueue:</strong> An unbounded priority queue based on a priority heap.</li>
                <li><strong>ArrayDeque:</strong> A resizable array implementation of the Deque interface.</li>
            </ul>

            <h3>Choosing the Right Data Structure</h3>
            <p>
                The choice of data structure depends on your specific requirements:
            </p>
            <ul>
                <li>Consider the operations you need to perform (add, remove, access).</li>
                <li>Think about whether order matters and if you need sorting.</li>
                <li>Consider thread-safety requirements for concurrent access.</li>
            </ul>

            <h3>Summary</h3>
            <p>
                Understanding different data structures in Java helps in designing efficient and effective programs. Each structure has its strengths and weaknesses, making it suitable for specific use cases.
            </p>

            <QuestionProcessor context='Java arrays and list overview'/>
        </div>
    );
};

export default ArraysAndLists;
