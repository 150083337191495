import React from 'react';
import ProjectCard from '../components/ProjectCard';
import './Projects.css';

const Projects = () => {
    return (
        <div className="projects">
            <h1>My Projects</h1>
            <ProjectCard title="Project 1" description="Description of project 1" />
            <ProjectCard title="Project 2" description="Description of project 2" />
        </div>
    );
};

export default Projects;
