import React, { useState } from 'react';
import './AquariumAutomation.css';
import AquariumSidebar from './AquariumSidebar';
import AquariumContent from './AquariumContent';
// Import Lessons

function AquariumAutomation() {
    const [lesson, setLesson] = useState(null);
    const [subtopic, setSubtopic] = useState(null);



    return (
        <div className="course-page">
            <AquariumSidebar setLesson={setLesson} setSubtopic={setSubtopic} />
            <AquariumContent lesson={lesson} subtopic={subtopic} />
        </div>
    );
}

export default AquariumAutomation;
