import React, { useState, useEffect } from 'react';
import './Lessons.css';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-eclipse';
import QuestionProcessor from './QuestionProcesser';

const ChallengeExercise = ({ configLocation }) => {
    const [question, setQuestion] = useState('');
    const [code, setCode] = useState('');
    const [userAnswer, setUserAnswer] = useState('');
    const [isQuerying, setIsQuerying] = useState(false);
    const [gradingHtmlResult, setGradingHtmlResult] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(configLocation);
                setQuestion(response.data.question);
                setCode(response.data.code);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [configLocation]);

    const handleUserAnswerChange = (value) => {
        setUserAnswer(value);
    };

    const gradeAnswer = async (answer, question) => {
        setIsQuerying(true);

        try {
            const response = await axios.get('https://45.77.158.54:5000/api/check_submission', {
                params: {
                    submission: `I asked the java programming question: "${question}". The user responded with: "${answer}". If the answer is correct, respond with the exact string "Correct! Good Job". If not, respond with the exact string "<p>Incorrect -> Here is a corrected answer</p>" then return the correct code answer in html formatted text. Do not return any surrounding markup tags.`
                }
            });

            setGradingHtmlResult(response.data); // Assuming response.data is HTML formatted text
        } catch (error) {
            console.error('Error fetching answer from server:', error);
            setGradingHtmlResult('There was an error processing your question. Please try again.');
        } finally {
            setIsQuerying(false);
        }
    };

    const handleSubmitAnswer = async () => {
        await gradeAnswer(userAnswer, question);
    };

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">Code Challenge</h2>
            <p>
                These challenges are intended to require more coding knowledge than you have been taught. This is because the best way to learn coding is to figure it out for yourself. You can use the <a href="https://docs.oracle.com/en/java/javase/17/" target="_blank" rel="noopener noreferrer">Oracle Java Documentation</a> for a complete reference guide. Alternatively, use the question field below to ask specific questions via the ChatGPT API.
            </p>
            <div className="grading-result" dangerouslySetInnerHTML={{ __html: question }}></div>

            <div className="code-block">
                <CopyToClipboard text={code}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <AceEditor
                    mode="java"
                    theme="eclipse"
                    value={code}
                    readOnly={true}
                    name="java_editor_display"
                    editorProps={{ $blockScrolling: true }}
                    style={{ width: '100%', height: '300px' }}
                />
            </div>
            <br />
            <div className="code-block">
                <AceEditor
                    mode="java"
                    theme="eclipse"
                    value={userAnswer}
                    onChange={handleUserAnswerChange}
                    name="java_editor_user"
                    editorProps={{ $blockScrolling: true }}
                    style={{ width: '100%', height: '500px' }}
                />
            </div>
            <br />
            <button
                className={`submit-button ${isQuerying ? 'disabled' : ''}`}
                onClick={handleSubmitAnswer}
                disabled={isQuerying}
            >
                Submit Answer
            </button>
            {gradingHtmlResult && (
                <div className="grading-result" dangerouslySetInnerHTML={{ __html: gradingHtmlResult }} />
            )}

            <QuestionProcessor context='The user is completing a code challenge. Give them hints but do not under any circumstance answer the entire question directly.' />
        </div>
    );
};

export default ChallengeExercise;
