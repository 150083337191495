// src/components/Examples/Lessons/Lesson2_S4_Loops.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css';
import QuestionProcessor from './QuestionProcesser';

const Lesson2_S4_Loops = () => {
    const forLoopCode = `// For loop example in Java
for (int i = 0; i < 5; i++) {
    System.out.println("Value of i: " + i);
}`;

    const whileLoopCode = `// While loop example in Java
int i = 0;
while (i < 5) {
    System.out.println("Value of i: " + i);
    i++;
}`;

    const doWhileLoopCode = `// Do-While loop example in Java
int i = 0;
do {
    System.out.println("Value of i: " + i);
    i++;
} while (i < 5);`;

    const enhancedForLoopCode = `// Enhanced For loop example in Java
int[] numbers = {1, 2, 3, 4, 5};
for (int number : numbers) {
    System.out.println("Number: " + number);
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.4: Loops</h2>
            <p>
                Loops are a fundamental concept in programming that allow you to execute a block of code repeatedly based on a condition. In Java, there are several types of loops, each with its specific use cases and benefits.
            </p>
            <h3>Types of Loops in Java</h3>
            <ul>
                <li><b>For Loop</b></li>
                <li><b>While Loop</b></li>
                <li><b>Do-While Loop</b></li>
                <li><b>Enhanced For Loop</b></li>
            </ul>
            <h3>For Loop</h3>
            <p>
                The for loop is used when you know in advance how many times you want to execute a statement or a block of statements. It is commonly used for iterating over arrays and collections.
            </p>
            <p>Example:</p>
            <div className="code-block">
                <CopyToClipboard text={forLoopCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {forLoopCode}
                </SyntaxHighlighter>
            </div>
            <p>
                In this example, the loop starts with <code>i = 0</code> and continues as long as <code>i &lt; 5</code>. It increments <code>i</code> by 1 in each iteration.
            </p>
            <h3>While Loop</h3>
            <p>
                The while loop is used when you want to repeat a block of code an unknown number of times until a specific condition is met. It checks the condition before executing the block of code.
            </p>
            <p>Example:</p>
            <div className="code-block">
                <CopyToClipboard text={whileLoopCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {whileLoopCode}
                </SyntaxHighlighter>
            </div>
            <p>
                In this example, the loop continues as long as <code>i &lt; 5</code>. The value of <code>i</code> is incremented in each iteration.
            </p>
            <h3>Do-While Loop</h3>
            <p>
                The do-while loop is similar to the while loop, but it checks the condition after executing the block of code. This ensures that the code block is executed at least once, even if the condition is false initially.
            </p>
            <p>Example:</p>
            <div className="code-block">
                <CopyToClipboard text={doWhileLoopCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {doWhileLoopCode}
                </SyntaxHighlighter>
            </div>
            <p>
                In this example, the loop will execute the block of code inside the <code>do</code> statement once before checking the condition <code>i &lt; 5</code>.
            </p>
            <h3>Enhanced For Loop</h3>
            <p>
                The enhanced for loop, also known as the "for-each" loop, is used for iterating over arrays or collections. It is more readable and eliminates the possibility of errors by abstracting the iterator or index variable.
            </p>
            <p>Example:</p>
            <div className="code-block">
                <CopyToClipboard text={enhancedForLoopCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {enhancedForLoopCode}
                </SyntaxHighlighter>
            </div>
            <p>
                In this example, the loop iterates over each element in the <code>numbers</code> array and prints it.
            </p>
            <h3>When to Use Each Loop</h3>
            <ul>
                <li><b>For Loop:</b> Use when you know the number of iterations in advance. Ideal for iterating over arrays with a known length.</li>
                <li><b>While Loop:</b> Use when the number of iterations is not known in advance and depends on a condition that is evaluated before each iteration.</li>
                <li><b>Do-While Loop:</b> Use when you need to ensure that the code block executes at least once, regardless of the condition.</li>
                <li><b>Enhanced For Loop:</b> Use for iterating over arrays or collections in a more readable and concise manner. It is especially useful for accessing each element directly without using an index.</li>
            </ul>
            <h3>Why Use Loops?</h3>
            <ul>
                <li><b>Efficiency:</b> Loops help in reducing the amount of code by eliminating the need for repetitive statements.</li>
                <li><b>Flexibility:</b> Loops allow for the execution of a block of code multiple times, making programs more dynamic and responsive to changing conditions.</li>
                <li><b>Readability:</b> Using loops can make code more readable and maintainable by clearly expressing repetitive logic.</li>
            </ul>
            <QuestionProcessor context='Java Loops'/>
        </div>
    );
};

export default Lesson2_S4_Loops;
