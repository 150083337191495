/* eslint-disable react/jsx-pascal-case */
import React from 'react';
// Import Lessons
import SimpleSensors from './Aquarium/Simple Sensors'; 
import DataAnalyisisAndVisualization from './Aquarium/Data Analyisis and Visualization';
import ActiveMeasures from './Aquarium/Active Measures';
import Conclusion from './Aquarium/Conclusion';





function AquariumContent({ lesson, subtopic }) {
    let content;

    switch (lesson) {
        case 'Lesson 1':
            switch (subtopic) {
                case 'Subtopic 1':
                    content = <SimpleSensors />;
                    break;
                case 'Subtopic 2':
                    content = <DataAnalyisisAndVisualization />;
                    break;
                case 'Subtopic 3':
                    content = <ActiveMeasures />;
                    break;
                case 'Subtopic 4':
                    content = <Conclusion />;
                    break;
                default:
                    content = <SimpleSensors />;
                    break;
            }
            break;
        default:
            content = <SimpleSensors />;
            break;
    }

    return (
        <div className="content">
            {content}
        </div>
    );
}

export default AquariumContent;
