import React, { useState } from 'react';
import './QuizComponents.css';
import axios from 'axios';

const Quiz_LongAnswer = ({ question, questionID, onChange }) => {
    const [questionText, setQuestionText] = useState('');
    const [gradingResult, setGradingResult] = useState(null);
    const [isQuerying, setIsQuerying] = useState(false);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false); // State to manage showing correct answer

    const handleQuestionChange = (e) => {
        setQuestionText(e.target.value);
        onChange(questionID, e.target.value);
    };

    const handleGradeAnswer = async () => {
        try {
            const result = await gradeLongAnswer(questionText, question);
            setGradingResult(result);
        } catch (error) {
            console.error('Error grading answer:', error);
            setGradingResult('There was an error processing your question. Please try again.');
        }
    };

    const gradeLongAnswer = async (answer, question) => {
        setIsQuerying(true); // Set querying state to true

        try {
            const response = await axios.get('https://45.77.158.54:5000/api/check_submission', {
                params: {
                    submission: `I asked the java programming question: "${question}". The user responded with: "${answer}". If the answer is correct, respond with the exact string "correct". If not, respond with the correct answer.`
                }
            });

            return response.data; // Return the entire API response as the grading result
        } catch (error) {
            console.error('Error fetching answer from server:', error);
            return 'There was an error processing your question. Please try again.';
        } finally {
            setIsQuerying(false); // Set querying state to false after request completes
        }
    };

    return (
        <div className="activity">
            <br />
            <p><strong>{questionID}.</strong> {question}</p>
            <textarea
                id="longAnswer"
                name="longAnswer"
                className="activity-textArea"
                value={questionText}
                onChange={handleQuestionChange}
                placeholder="Type your answer here..."
                disabled={gradingResult !== null} // Disable textarea when grading
                style={{ resize: 'vertical' }} // Make textarea resize vertically only
            ></textarea>
        </div>
    );
};

export default Quiz_LongAnswer;
