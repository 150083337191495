import React, { useState } from 'react';
import './Aquarium.css';

const DataAnalyisisAndVisualization = () => {

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">Phase #2: Data Analyisis And Visualization</h4>
            <p>
                <br></br>
                <strong>The folloing implementations are in progress and will be update as they are implemented.</strong>
                <br></br>
            </p>
        </div>
    );
};

export default DataAnalyisisAndVisualization;
