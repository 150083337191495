// src/components/Examples/Lessons/Lesson2_S5_1_LinkedList.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const Lesson2_S5_3_LinkedList = () => {
    // Example code for LinkedList declaration and initialization
    const linkedListExampleCode = `// LinkedList declaration and initialization
import java.util.LinkedList;

public class Main {
    public static void main(String[] args) {
        // Declare LinkedList of strings
        LinkedList<String> names = new LinkedList<>();

        // Add elements to LinkedList
        names.add("Alice");
        names.add("Bob");
        names.add("Charlie");
        names.add("David");
        names.add("Eve");

        // Print LinkedList elements
        System.out.println(names);
    }
}`;

    // Example code for iterating through a LinkedList
    const iterateLinkedListCode = `// Iterating through a LinkedList
for (String name : names) {
    System.out.println(name); // Print each element
}`;

    // Example code for adding an element at the beginning of a LinkedList
    const addFirstElementCode = `// Adding an element at the beginning of a LinkedList
names.addFirst("Frank");
System.out.println(names); // Print LinkedList after addition`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.5.3: LinkedList</h2>
            <p>
                LinkedList is a doubly linked list implementation of the List interface in Java. It provides efficient insertion and deletion operations, but requires more memory compared to ArrayList.
            </p>

            <h3>LinkedList Declaration and Initialization</h3>
            <p>
                LinkedLists are declared with a specific type and can be initialized with values.
            </p>
            <div className="code-block">
                <CopyToClipboard text={linkedListExampleCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {linkedListExampleCode}
                </SyntaxHighlighter>
            </div>

            <h3>Iterating Through a LinkedList</h3>
            <p>
                You can iterate through a LinkedList using an enhanced for loop.
            </p>
            <div className="code-block">
                <CopyToClipboard text={iterateLinkedListCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {iterateLinkedListCode}
                </SyntaxHighlighter>
            </div>

            <h3>Adding an Element at the Beginning of a LinkedList</h3>
            <p>
                You can add an element at the beginning of a LinkedList using the <code>addFirst()</code> method.
            </p>
            <div className="code-block">
                <CopyToClipboard text={addFirstElementCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {addFirstElementCode}
                </SyntaxHighlighter>
            </div>

            <h3>Summary</h3>
            <p>
                LinkedLists provide a flexible way to work with collections of elements in Java, especially when frequent insertions or deletions are required. They use more memory than ArrayList due to the overhead of maintaining nodes.
            </p>

            <QuestionProcessor context='Java linkedLists'/>
        </div>
    );
};

export default Lesson2_S5_3_LinkedList;
