// src/components/Examples/Lessons/Lesson2_S3_ConditionalStatements.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const ConditionalStatements = () => {
    // Example code for If-Else Statements
    const ifElseStatementsCode = `// If-Else Statements in Java
public class Main {
    public static void main(String[] args) {
        int number = 10;
        
        if (number > 0) {
            System.out.println("The number is positive.");
        } else {
            System.out.println("The number is zero or negative.");
        }
    }
}`;

    // Example code for Switch Statements
    const switchStatementsCode = `// Switch Statements in Java
public class Main {
    public static void main(String[] args) {
        int day = 2;
        String dayName;
        
        switch (day) {
            case 1:
                dayName = "Sunday";
                break;
            case 2:
                dayName = "Monday";
                break;
            default:
                dayName = "Invalid day";
                break;
        }
        
        System.out.println("Day: " + dayName);
    }
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.3: Conditional Statements in Java</h2>
            <p>
                Conditional statements in Java allow you to execute certain blocks of code based on whether a condition is true or false. They include if-else and switch statements.
            </p>

            <h3>If-Else Statements</h3>
            <p>
                If-else statements execute a block of code if a specified condition is true. If the condition is false, another block of code can be executed.
            </p>
            <div className="code-block">
                <CopyToClipboard text={ifElseStatementsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {ifElseStatementsCode}
                </SyntaxHighlighter>
            </div>

            <h3>Switch Statements</h3>
            <p>
                Switch statements allow you to select one of many code blocks to be executed based on a specified expression.
            </p>
            <div className="code-block">
                <CopyToClipboard text={switchStatementsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {switchStatementsCode}
                </SyntaxHighlighter>
            </div>
            <QuestionProcessor context='Java Conditional Statments'/>
        </div>
    );
};

export default ConditionalStatements;
