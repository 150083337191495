import React from 'react';
import './MinecraftMapPack.css'; // Import the CSS file
import CanadianTrafficSign from '../../img/Canadian Traffic Sign.png';
import CharacterGreen from '../../img/Character Green.png';
import Emojis from '../../img/Emojis.png';
import ExteriorDecorations from '../../img/Exterior Decorations.png';
import MiscTrafficDecorations from '../../img/Misc Traffic Decorations.png';
import UKTrafficSigns from '../../img/UK Traffic Signs.png';
import USTrafficSigns from '../../img/US Traffic Signs.png';
import InGameExample from '../../img/InGameExample.png';
import Slideshow from '../Slideshow';

const MinecraftMapPack = () => {
    // Array of images to be used in the slideshow
    const images = [
        CanadianTrafficSign,
        CharacterGreen,
        Emojis,
        ExteriorDecorations,
        MiscTrafficDecorations,
        UKTrafficSigns,
        USTrafficSigns
    ];

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">Minecraft Map Pack</h2>
            <div>
                <p>As part of my voluntary work for a small Minecraft server, I dedicate my time to developing plugins and creating content. Recently, I crafted a map pack specifically designed for the server's players. These maps are available in-game and can be used by players to enhance their builds with unique decorations.</p>
                <br />
                <p>Here are some examples of the maps I created using Photoshop.</p>
                <Slideshow images={images} />
                <br />
                <p>As you can see in the photos above, the size of these images is very small (128px x 128px). This is because of the maximum size allowed for maps in the game. As a result, I had to be creative in accurately representing the original decorations I intended to showcase.</p>
                <p>This is an example of how the maps are used in-game. In this case, I used the stone tile pattern for the kitchen backsplash, a double wall socket, and a picture of a baby monkey.</p>
                <img src={InGameExample} alt="Screenshot of the game Minecraft with the maps in use." className="lesson-image" />
            </div>
        </div>
    );
};

export default MinecraftMapPack;
