import React from 'react';
import './Home.css';
import profilePhoto from '../img/profile.jpg'; // Ensure you adjust the path
import project1 from '../img/javaProject.PNG';
import project2 from '../img/Aquarium.jpg';

const Home = () => {
    return (
        <div className="homepage">
            <section className="intro">
                <img src={profilePhoto} alt="Jaron Lorentz" className="profile-photo" />
                <h1>Jaron Lorentz</h1>
                <p>Professional Summary: Course Developer, Web Developer, IT Support/Instructor with a strong background in communication, problem-solving, and organizational skills.</p>
            </section>

            <section className="portfolio-highlights">
                <h2>Portfolio Highlights</h2>
                <div className="portfolio-item">
                    <img src={project1} alt="Project 1" />
                    <div className="portfolio-info">
                        <h3>Java 101</h3>
                        <p>A beginner-friendly course meticulously crafted from the ground up, designed to introduce you to the world of Java programming. This course is enriched with custom interactive elements, including quizzes and an AI-based assistant to answer your questions in real-time, ensuring a smooth and engaging learning experience.</p>
                    </div>
                </div>
                <div className="portfolio-item">
                    <img src={project2} alt="Project 2" />
                    <div className="portfolio-info">
                        <h3>Aquarium Automation</h3>
                        <p>This project aims to utilize Adreno sensors and controls to fully automate a turtle aquarium. These sensors will monitor and control parameters such as water level, temperature, and nitrate levels. The data collected will be used to generate automatic reports on the tank's health and to promptly address any issues that arise.</p>
                    </div>
                </div>
            </section>

            <section className="skills">
                <h2>Skills</h2>
                <ul>
                    <li>Communication</li>
                    <li>Problem-solving</li>
                    <li>Decision-making</li>
                    <li>Organizational Skills</li>
                    <li>Adaptability</li>
                    <li>React.js</li>
                    <li>JavaScript</li>
                    <li>Python</li>
                    <li>HTML/CSS</li>
                    <li>SQL</li>
                    <li>.NET</li>
                    <li>Java</li>
                    <li>Adobe Photoshop</li>
                    <li>Adobe  Illistrator</li>
                    <li>Git & GitHub</li>
                    <li>C#</li>
                    <li>AWS (Amazon Web Services)</li>
                    <li>C++</li>
                    <li>Unity</li>
                    <li>TypeScript</li>
                    <li>Learning Management System</li>
                    <li>Leadership</li>
                    <li>Teamwork</li>
                    <li>Time Management</li>
                    <li>Critical Thinking</li>
                    <li>Creativity</li>
                    <li>Attention to Detail</li>
                    <li>Customer Service</li>
                    <li>Multitasking</li>
                    <li>Flexibility</li>
                </ul>
            </section>

            <section className="contact">
                <h2>Contact</h2>
                <p>Email: <a href="mailto:jjlortez.jl@gmail.com"><i className="fas fa-envelope"></i> jjlortez.jl@gmail.com</a></p>
                <p>Phone: <i className="fas fa-phone"></i> (519) 949-1718</p>
                <p>LinkedIn: <a href="https://www.linkedin.com/in/jaron-lorentz-a9b6a0271" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i> LinkedIn Profile</a></p>
                <p>GitHub: <a href="https://github.com/JJL0rtez" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i> GitHub Profile</a></p>
            </section>

            <section className="call-to-action">
                <h2>Get in Touch</h2>
                <p>If you are interested in working with me or want to learn more about my work, please get in touch!</p>
                <button onClick={() => window.location.href = 'mailto:jjlortez.jl@gmail.com'}>Contact Me</button>
                <br></br>
            </section>
        </div>
    );
};

export default Home;
