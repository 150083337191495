import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Java code example for I/O Streams
const ioStreamCode = `
  // I/O Streams in Java

  import java.io.*;

  public class IOStreamExample {
      public static void main(String[] args) {
          // Example 1: Reading from a file
          try (BufferedReader br = new BufferedReader(new FileReader("input.txt"))) {
              String line;
              while ((line = br.readLine()) != null) {
                  System.out.println(line);
              }
          } catch (IOException e) {
              System.err.println("Error reading from file: " + e.getMessage());
          }

          // Example 2: Writing to a file
          try (BufferedWriter bw = new BufferedWriter(new FileWriter("output.txt"))) {
              bw.write("Hello, World!");
          } catch (IOException e) {
              System.err.println("Error writing to file: " + e.getMessage());
          }
      }
  }
`;

const ReferenceMaterial = () => {
    // Function to handle copying code to clipboard
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">Reference Material</h4>
            <ul>
                <li>
                    <strong>Oracle's Java Tutorials:</strong> Official Java tutorials provided by Oracle. They cover all aspects of Java programming and are well-structured for beginners and advanced users alike.
                    <br />
                    Website: <a href="https://docs.oracle.com/javase/tutorial/" target="_blank" rel="noopener noreferrer">Oracle Java Tutorials</a>
                </li>
                <li>
                    <strong>Java API Documentation:</strong> The official Java API documentation provides a comprehensive reference for all Java classes and packages.
                    <br />
                    Website: <a href="https://docs.oracle.com/en/java/javase/17/docs/api/index.html" target="_blank" rel="noopener noreferrer">Java SE Documentation</a>
                </li>
                <li>
                    <strong>Books:</strong>
                    <ul>
                        <li><em>Java: The Complete Reference, Thirteenth Edition</em> by Herbert Schildt, Danny Coward: The most comprehensive reference book avaliable for Java SE 21.</li>
                        <li><em>Effective Java</em> by Joshua Bloch: Offers practical advice on using Java effectively and covers best practices.</li>
                        <li><em>Head First Java</em> by Kathy Sierra and Bert Bates: A beginner-friendly book that covers Java fundamentals in a highly engaging manner.</li>
                    </ul>
                </li>
            </ul>

        </div>
    );
};

export default ReferenceMaterial;
