import React, { useState, useEffect } from 'react';
import QuizLongAnswer from './Quiz_LongAnswer';
import QuizMultipleChoice from './Quiz_MultipleChoice';
import './QuizComponents.css';
import axios from 'axios';

const QuizPage = ({ configLocation }) => {
    const [quizConfig, setQuizConfig] = useState(null);
    const [userAnswers, setUserAnswers] = useState({});
    const [gradingResult, setGradingResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    let questionID = 1;

    useEffect(() => {
        fetch(configLocation)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setQuizConfig(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching quiz data:', error);
                setLoading(false);
            });
    }, [configLocation]);

    const handleAnswerChange = (questionID, answer) => {
        setUserAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionID]: answer,
        }));
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const result = await gradeQuiz();
            setGradingResult(result);
        } catch (error) {
            console.error('Error grading quiz:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const gradeQuiz = async () => {
        let score = 0;
        const gradedAnswers = await Promise.all(quizConfig.questions.map(async (question, index) => {
            const currentQuestionID = questionID + index;
            const userAnswer = userAnswers[currentQuestionID] || {};
            let isCorrect = false;
            let correctAnswer = '';

            if (question.type === 'multipleChoice') {
                if (userAnswer === question.correctAnswer) {
                    score += 1;
                    isCorrect = true;
                } else {
                    correctAnswer = question.correctAnswer;
                }
            } else if (question.type === 'longAnswer') {
                try {
                    const result = await gradeLongAnswer(userAnswer, question.question);
                    if (result === 'correct') {
                        score += 1;
                        isCorrect = true;
                    } else {
                        correctAnswer = result; // This will be the correct answer returned by the server
                    }
                } catch (error) {
                    console.error('Error grading long answer:', error);
                    correctAnswer = 'There was an error processing your question. Please try again.';
                }
            } else if (question.type === 'matching') {
                const correctPairs = question.pairs.reduce((acc, pair) => {
                    acc[pair.key] = pair.right;
                    return acc;
                }, {});

                const correctMatches = Object.keys(correctPairs).every(key => correctPairs[key] === userAnswer[key]);

                if (correctMatches) {
                    score += 1;
                    isCorrect = true;
                } else {
                    correctAnswer = question.pairs.map(pair => `${pair.left} - ${pair.right}`).join('\n');
                }
            }

            return {
                question: question.question || '',
                userAnswer: question.type === 'matching'
                    ? question.pairs.map(pair => `(${pair.key + 1}. ${pair.left} - ${userAnswer[pair.key] || ''})`).join(', ')
                    : userAnswer || '',
                correctAnswer,
                isCorrect,
            };
        }));

        return { score, gradedAnswers };
    };

    const gradeLongAnswer = async (answer, question) => {
        try {
            const response = await axios.get('https://45.77.158.54:5000/api/check_submission', {
                params: {
                    submission: `I asked the java programming question: "${question}". The user responded with: "${answer}". If the answer is correct, respond with "correct". If not, respond with the correct java programming answer. Do not surround code with markdown. If answer was "[object Object] return with "Please enter an answer"`
                }
            });

            return response.data; // Return the server's response indicating correctness or the correct answer
        } catch (error) {
            console.error('Error fetching answer from server:', error);
            throw new Error('There was an error processing your question. Please try again.');
        }
    };

    const renderQuizComponent = (question, index) => {
        const currentQuestionID = questionID + index;
        switch (question.type) {
            case 'longAnswer':
                return <QuizLongAnswer key={index} question={question.question} questionID={currentQuestionID} onChange={handleAnswerChange} />;
            case 'multipleChoice':
                return (
                    <div key={index}>
                        <QuizMultipleChoice
                            question={question.question}
                            options={question.options}
                            correctAnswer={question.correctAnswer}
                            questionID={currentQuestionID}
                            onChange={handleAnswerChange}
                            selectedAnswer={userAnswers[currentQuestionID]}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return <div className="lesson-content">Loading...</div>;
    }

    return (
        <div className="lesson-content">
            <h1>Quiz</h1>
            {quizConfig.questions.map((question, index) => renderQuizComponent(question, index))}
            <button onClick={handleSubmit} disabled={isSubmitting} className='submit-button'>Submit</button>
            {isSubmitting && <p>Submitting...</p>}
            {gradingResult && (
                <div className="grading-result">
                    <h2>Quiz Result</h2>
                    <p>Your score: {gradingResult.score}/{quizConfig.questions.length}</p>
                    {gradingResult.gradedAnswers.map((gradedAnswer, index) => (
                        <div key={index} className="graded-answer">
                            {!gradedAnswer.isCorrect && (
                                <p><strong>Question {index + 1}:</strong> {gradedAnswer.question}</p>
                            )}
                            {!gradedAnswer.isCorrect && (
                                <div>
                                    <p> <strong>Correct Answer:</strong><br />{gradedAnswer.correctAnswer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default QuizPage;
