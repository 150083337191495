// src/components/Examples/Lessons/Lesson2_S5_4_OtherLists.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const Lesson2_S5_4_OtherLists = () => {
    // Description of Vector
    const vectorDescription = `Vector: Similar to ArrayList but synchronized (thread-safe). It is part of the Java Collections Framework and implements the List interface. Vector is synchronized, which means it is thread-safe, but this synchronization comes with some performance overhead.`;

    // Example code for Vector declaration and initialization
    const vectorExampleCode = `// Vector declaration and initialization
import java.util.Vector;

public class Main {
    public static void main(String[] args) {
        // Declare Vector of strings
        Vector<String> names = new Vector<>();

        // Add elements to Vector
        names.add("Alice");
        names.add("Bob");
        names.add("Charlie");
        names.add("David");
        names.add("Eve");

        // Print Vector elements
        System.out.println(names);
    }
}`;

    // Description of Stack
    const stackDescription = `Stack: A subclass of Vector, implements a last-in, first-out (LIFO) stack of elements. It extends Vector with five operations that allow a vector to be treated as a stack.`;

    // Example code for Stack operations
    const stackExampleCode = `// Stack operations
import java.util.Stack;

public class Main {
    public static void main(String[] args) {
        // Create a Stack
        Stack<String> stack = new Stack<>();

        // Push elements onto the stack
        stack.push("Java");
        stack.push("Python");
        stack.push("JavaScript");

        // Pop elements from the stack
        String element1 = stack.pop();
        String element2 = stack.pop();

        // Print popped elements
        System.out.println("Popped: " + element1 + ", " + element2);
    }
}`;

    // Description of Queue
    const queueDescription = `Queue: Interface representing a FIFO (First-In-First-Out) queue. It extends Collection and is part of the Java Collections Framework. Queues typically order elements in a FIFO manner, where elements are inserted at the end and removed from the beginning.`;

    // Example code for Queue operations
    const queueExampleCode = `// Queue operations
import java.util.LinkedList;
import java.util.Queue;

public class Main {
    public static void main(String[] args) {
        // Create a Queue using LinkedList
        Queue<String> queue = new LinkedList<>();

        // Add elements to the Queue
        queue.add("Element 1");
        queue.add("Element 2");
        queue.add("Element 3");

        // Remove elements from the Queue
        String removedElement = queue.remove();

        // Print removed element
        System.out.println("Removed Element: " + removedElement);
    }
}`;

    // Description of PriorityQueue
    const priorityQueueDescription = `PriorityQueue: An unbounded priority queue based on a priority heap. It is part of the Java Collections Framework and provides a queue that orders elements according to their natural order or a specified comparator.`;

    // Example code for PriorityQueue operations
    const priorityQueueExampleCode = `// PriorityQueue operations
import java.util.PriorityQueue;

public class Main {
    public static void main(String[] args) {
        // Create a PriorityQueue
        PriorityQueue<Integer> pq = new PriorityQueue<>();

        // Add elements to the PriorityQueue
        pq.add(10);
        pq.add(5);
        pq.add(20);

        // Remove elements from the PriorityQueue
        int removedElement = pq.poll();

        // Print removed element
        System.out.println("Removed Element: " + removedElement);
    }
}`;

    // Description of ArrayDeque
    const arrayDequeDescription = `ArrayDeque: A resizable array implementation of the Deque interface. It is part of the Java Collections Framework and provides an efficient way to add and remove elements from both ends of the deque.`;

    // Example code for ArrayDeque operations
    const arrayDequeExampleCode = `// ArrayDeque operations
import java.util.ArrayDeque;

public class Main {
    public static void main(String[] args) {
        // Create an ArrayDeque
        ArrayDeque<String> deque = new ArrayDeque<>();

        // Add elements to the beginning of the deque
        deque.addFirst("Element 1");
        deque.addFirst("Element 2");

        // Add elements to the end of the deque
        deque.addLast("Element 3");
        deque.addLast("Element 4");

        // Remove elements from the beginning and end of the deque
        String firstElement = deque.removeFirst();
        String lastElement = deque.removeLast();

        // Print removed elements
        System.out.println("Removed Elements: " + firstElement + ", " + lastElement);
    }
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.5.4: Other Lists</h2>

            <div className="description">
                <h3>Vector</h3>
                <p>{vectorDescription}</p>
                <div className="code-block">
                    <CopyToClipboard text={vectorExampleCode}>
                        <button className="copy-button">Copy Code</button>
                    </CopyToClipboard>
                    <SyntaxHighlighter language="java" style={atomOneLight}>
                        {vectorExampleCode}
                    </SyntaxHighlighter>
                </div>
            </div>

            <div className="description">
                <h3>Stack</h3>
                <p>{stackDescription}</p>
                <div className="code-block">
                    <CopyToClipboard text={stackExampleCode}>
                        <button className="copy-button">Copy Code</button>
                    </CopyToClipboard>
                    <SyntaxHighlighter language="java" style={atomOneLight}>
                        {stackExampleCode}
                    </SyntaxHighlighter>
                </div>
            </div>

            <div className="description">
                <h3>Queue</h3>
                <p>{queueDescription}</p>
                <div className="code-block">
                    <CopyToClipboard text={queueExampleCode}>
                        <button className="copy-button">Copy Code</button>
                    </CopyToClipboard>
                    <SyntaxHighlighter language="java" style={atomOneLight}>
                        {queueExampleCode}
                    </SyntaxHighlighter>
                </div>
            </div>

            <div className="description">
                <h3>PriorityQueue</h3>
                <p>{priorityQueueDescription}</p>
                <div className="code-block">
                    <CopyToClipboard text={priorityQueueExampleCode}>
                        <button className="copy-button">Copy Code</button>
                    </CopyToClipboard>
                    <SyntaxHighlighter language="java" style={atomOneLight}>
                        {priorityQueueExampleCode}
                    </SyntaxHighlighter>
                </div>
            </div>

            <div className="description">
                <h3>ArrayDeque</h3>
                <p>{arrayDequeDescription}</p>
                <div className="code-block">
                    <CopyToClipboard text={arrayDequeExampleCode}>
                        <button className="copy-button">Copy Code</button>
                    </CopyToClipboard>
                    <SyntaxHighlighter language="java" style={atomOneLight}>
                        {arrayDequeExampleCode}
                    </SyntaxHighlighter>
                </div>
            </div>

            <QuestionProcessor context='java vector, Stack, Queue, PriorityQueue and ArrayDeque'/>
        </div>
    );
};

export default Lesson2_S5_4_OtherLists;
