import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const Lesson1Subtopic1 = () => {
    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">1.1: History of Java</h4>
            <p>
                Java is a widely used programming language developed by James Gosling at Sun Microsystems (which has since been acquired by Oracle Corporation). It was first released in 1995 as a core component of Sun Microsystems' Java platform.
            </p>
            <p>
                The language was initially designed for interactive television, but it quickly became popular for internet programming due to its platform independence, which means that Java programs can run on any device with a Java Virtual Machine (JVM), regardless of the underlying hardware and software.
            </p>
            <p>
                Over the years, Java has evolved and expanded its features, becoming one of the most popular programming languages in the world. It is widely used in web development, mobile application development (Android), enterprise software development, and more.
            </p>
            <QuestionProcessor context='History of Java'/>
        </div>
    );
};

export default Lesson1Subtopic1;
