import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MovieSearch from '../components/MovieSearch';
import MinecraftMapPack from '../components/Examples/MinecraftMapPack'; // Import your MinecraftMapPack component
//import MovieComparison from '../components/MovieComparison';
import './Demos.css'; // Import the styles
import MatchingActivity from '../components/Examples/MatchingActivity';
import JavaCourse from '../components/Examples/JavaCourse';
import AquariumAutomation from '../components/Examples/AquariumAutomation';
const demos = [
    {
        id: 'movieSearch',
        title: '6 Degrees of Separation',
        description: 'Search for media using the IMDb API. Then use ChatGPTs generative ai to connect the two.',
        tags: ['React', 'API', 'JavaScript', 'Python'],
        path: '/movie-search', // Route path for the demo
    },
    {
        id: 'demo2',
        title: 'Minecraft Map Pack',
        description: 'We have crafted a collection of unique elements such as stop signs and window frames that players can seamlessly integrate into their Minecraft worlds.Ideal for showcasing creative and technical expertise in game modification and design.',
        tags: ['Adobe Photoshop', 'XMAL'],
        path: '/minecraft-map-pack', // Route path for the demo
    },
    {
        id: 'demo3',
        title: 'Modernizing Flash Applications',
        description: 'Converted a Flash app to React to modernizes the technology stack and enhance performance, security, and maintainability.',
        tags: ['Adobe Flash', 'Adobe Photoshop', 'Action Script', 'React', 'Javascript', 'CSS'],
        path: '/flash-matching-activity', // Route path for the demo
    },
    {
        id: 'demo4',
        title: 'Java 101 Course',
        description: 'A beginners java course complete with quizes, self checking exercises and a final project.',
        tags: ['Java', 'Adobe Photoshop', 'Javascript', 'HTML5', 'CSS', 'Python'],
        path: '/java-course', // Route path for the demo
    },
    {
        id: 'demo5',
        title: 'Auqarium Automation',
        description: 'An in-progress project to remotly monitor and manage a turtle aquarium.',
        tags: ['C++', 'Arduino', 'Data Visulation', 'SQL'],
        path: '/aquarium-automation', // Route path for the demo
    },
    // Add more demos as needed
];

const DemosPage = () => {
    const [selectedDemo, setSelectedDemo] = useState('/movie-search');
    const [selectedMovies, setSelectedMovies] = useState({
        movie1: null,
        movie2: null,
    });

    const navigate = useNavigate();

    const handleSelectDemo = (path) => {
        setSelectedDemo(path); // Set the selected demo path
        navigate(path); // Navigate to the demo path
    };

    const handleSelectMovie = (movie) => {
        if (!selectedMovies.movie1) {
            setSelectedMovies({ ...selectedMovies, movie1: movie });
        } else if (!selectedMovies.movie2) {
            setSelectedMovies({ ...selectedMovies, movie2: movie });
        } else {
            setSelectedMovies({ movie1: movie, movie2: null });
        }
    };

    const renderDemo = () => {
        switch (selectedDemo) {
            case '/movie-search':
                return <MovieSearch onSelectMovie={handleSelectMovie} />;
            case '/minecraft-map-pack':
                return <MinecraftMapPack />;
            case '/flash-matching-activity':
                return <MatchingActivity />;
            case '/java-course':
                return <JavaCourse />;
            case '/aquarium-automation':
                return <AquariumAutomation />;
            default:
                return null;
        }
    };

    return (
        <div className="demos-page light-theme">
            <div className="nav-box">
                <div className="nav-buttons">
                    {demos.map((demo) => (
                        <button
                            key={demo.id}
                            onClick={() => handleSelectDemo(demo.path)}
                            className={`demo-button ${selectedDemo === demo.path ? 'selected' : ''}`}
                        >
                            <div className="demo-info">
                                <h3>{demo.title}</h3>
                                <p>{demo.description}</p>
                                <div>
                                    {demo.tags.map((tag, index) => (
                                        <span key={index} className="tag">
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <div className="demo-details">
                {renderDemo()}
            </div>
        </div>
    );
};

export default DemosPage;
