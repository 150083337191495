import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Java code example for I/O Streams
const ioStreamCode = `
  // I/O Streams in Java

  import java.io.*;

  public class IOStreamExample {
      public static void main(String[] args) {
          // Example 1: Reading from a file
          try (BufferedReader br = new BufferedReader(new FileReader("input.txt"))) {
              String line;
              while ((line = br.readLine()) != null) {
                  System.out.println(line);
              }
          } catch (IOException e) {
              System.err.println("Error reading from file: " + e.getMessage());
          }

          // Example 2: Writing to a file
          try (BufferedWriter bw = new BufferedWriter(new FileWriter("output.txt"))) {
              bw.write("Hello, World!");
          } catch (IOException e) {
              System.err.println("Error writing to file: " + e.getMessage());
          }
      }
  }
`;

const Lesson2_S6_IOStream = () => {
    // Function to handle copying code to clipboard
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.6: I/O Streams</h4>
            <p>
                In Java, I/O (Input/Output) streams are used to read data from and write data to different sources, such as files, network connections, or other input/output devices. They provide a way to handle the flow of data in and out of a program efficiently.
            </p>

            <div className="code-block">
                {/* SyntaxHighlighter component to display Java code */}
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {ioStreamCode.trim()}
                </SyntaxHighlighter>
                {/* Button to copy code to clipboard */}
                <CopyToClipboard text={ioStreamCode.trim()}>
                    <button className="copy-button" onClick={() => handleCopy(ioStreamCode.trim())}>
                        Copy Code
                    </button>
                </CopyToClipboard>
            </div>

            <p>
                This Java example demonstrates different uses of I/O streams: reading from a file and writing to a file.
            </p>

            <h3>Understanding I/O Streams</h3>
            <p>
                I/O streams are categorized into two types: input streams for reading data and output streams for writing data. They can be used to interact with various data sources, such as files, databases, or network sockets.
            </p>

            <h3>When to Use I/O Streams</h3>
            <p>
                Use I/O streams whenever your program needs to read data from or write data to an external source. Common use cases include reading configuration files, processing user input, saving application state, or communicating over a network.
            </p>

            <h3>Key Concepts</h3>
            <ul>
                <li><strong>InputStream</strong>: Reads raw binary data.</li>
                <li><strong>OutputStream</strong>: Writes raw binary data.</li>
                <li><strong>Reader</strong>: Reads character streams.</li>
                <li><strong>Writer</strong>: Writes character streams.</li>
                <li><strong>Buffering</strong>: Improves performance by reducing the number of I/O operations.</li>
                <li><strong>Exception Handling</strong>: Necessary for dealing with I/O errors.</li>
            </ul>

            {/* Component for processing questions related to the lesson */}
            <QuestionProcessor context='Java IO Stream'/>
        </div>
    );
};

export default Lesson2_S6_IOStream;
