import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Java code example for the for loop
const forLoopCode = `
  // For Loop in Java

  public class ForLoopExample {
      public static void main(String[] args) {
          // Example 1: Basic for loop
          for (int i = 1; i <= 5; i++) {
              System.out.println("Count is: " + i);
          }

          // Example 2: For loop with an array
          int[] numbers = {1, 2, 3, 4, 5};
          for (int number : numbers) {
              System.out.println("Number is: " + number);
          }

          // Example 3: For loop to iterate over a string
          String greeting = "Hello";
          for (int i = 0; i < greeting.length(); i++) {
              char ch = greeting.charAt(i);
              System.out.println("Character at position " + i + " is: " + ch);
          }
      }
  }
`;

const Lesson2Subtopic4_1 = () => {
    // Function to handle copying code to clipboard
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    // Function to handle copying array example code to clipboard
    const handleArrayCopy = () => {
        alert('Code copied to clipboard!');
    };

    // Java code example for the for loop with an array
    const arrayExampleCode = `
      // Example 2: For loop with an array
      int[] numbers = {1, 2, 3, 4, 5};
      for (int number : numbers) {
          System.out.println("Number is: " + number);
      }
    `;

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.4.1: For Loop</h4>
            <p>
                The <code>for</code> loop in Java is used to execute a block of code repeatedly for a fixed number of times. It provides a concise way to write repetitive code.
            </p>

            <div className="code-block">
                {/* SyntaxHighlighter component to display Java code */}
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {forLoopCode.trim()}
                </SyntaxHighlighter>
                {/* Button to copy code to clipboard */}
                <CopyToClipboard text={forLoopCode.trim()}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
            </div>

            <p>
                This Java example demonstrates different uses of the <code>for</code> loop: iterating with a basic counter, over an array, and over a string.
            </p>

            <h4 className="subtopic-header">Example 2: For loop with an array</h4>
            <div className="code-block">
                {/* SyntaxHighlighter component to display array example code */}
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {arrayExampleCode.trim()}
                </SyntaxHighlighter>
                {/* Button to copy array example code to clipboard */}
                <CopyToClipboard text={arrayExampleCode.trim()}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
            </div>

            <p>
                This example shows how to use a <code>for</code> loop to iterate over an array in Java.
            </p>

            {/* Component for processing questions related to the lesson */}
            <QuestionProcessor context='Java for loops'/>
        </div>
    );
};

export default Lesson2Subtopic4_1;
