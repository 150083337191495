import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item">
                    Jaron Lorentz
                </Link>
                <button className="navbar-toggle" onClick={toggleNavbar}>
                    &#9776;
                </button>
            </div>
            <div className={`navbar-buttons ${isOpen ? 'active' : ''}`}>
                <Link to="/" className="navbar-button">
                    Home
                </Link>
                <Link to="/demos" className="navbar-button">
                    Examples
                </Link>
                <Link to="/contact" className="navbar-button">
                    Contact
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
