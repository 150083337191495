// src/components/Examples/Lessons/Lesson2_S2_Operators.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const Lesson2_S2_Operators = () => {
    // Example code for Arithmetic Operators
    const arithmeticOperatorsCode = `// Arithmetic Operators
    int a = 10;
    int b = 5;
    int sum = a + b; // Addition
    int difference = a - b; // Subtraction
    int product = a * b; // Multiplication
    int quotient = a / b; // Division
    int remainder = a % b; // Modulus`;

    // Example code for Relational Operators
    const relationalOperatorsCode = `// Relational Operators
    int a = 10;
    int b = 5;
    boolean isEqual = (a == b); // Equal to
    boolean isNotEqual = (a != b); // Not equal to
    boolean isGreater = (a > b); // Greater than
    boolean isLess = (a < b); // Less than
    boolean isGreaterOrEqual = (a >= b); // Greater than or equal to
    boolean isLessOrEqual = (a <= b); // Less than or equal to`;

    // Example code for Logical Operators
    const logicalOperatorsCode = `// Logical Operators
    boolean x = true;
    boolean y = false;
    boolean resultAnd = x && y; // Logical AND
    boolean resultOr = x || y; // Logical OR
    boolean resultNotX = !x; // Logical NOT`;

    // Example code for Bitwise Operators
    const bitwiseOperatorsCode = `// Bitwise Operators
    int a = 5; // Binary: 101
    int b = 3; // Binary: 011
    int resultAnd = a & b; // Bitwise AND
    int resultOr = a | b; // Bitwise OR
    int resultXor = a ^ b; // Bitwise XOR
    int resultComplement = ~a; // Bitwise Complement
    int resultLeftShift = a << 1; // Left shift by 1
    int resultRightShift = a >> 1; // Right shift by 1`;

    // Example code for Assignment Operators
    const assignmentOperatorsCode = `// Assignment Operators
    int a = 10;
    int b = 5;
    a += b; // Equivalent to a = a + b;
    a -= b; // Equivalent to a = a - b;
    a *= b; // Equivalent to a = a * b;
    a /= b; // Equivalent to a = a / b;
    a %= b; // Equivalent to a = a % b;`;

    // Example code for Ternary Operator
    const ternaryOperatorCode = `// Ternary Operator
    int a = 10;
    int b = 5;
    int max = (a > b) ? a : b; // If a is greater than b, max = a, else max = b`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.2: Operators</h2>
            <p>
                Operators in Java are special symbols that perform specific operations on one, two, or three operands, and then return a result.
            </p>

            <h3>Arithmetic Operators</h3>
            <p>
                Arithmetic operators are used to perform basic mathematical operations like addition, subtraction, multiplication, division, and modulus.
            </p>
            <div className="code-block">
                <CopyToClipboard text={arithmeticOperatorsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {arithmeticOperatorsCode}
                </SyntaxHighlighter>
            </div>

            <h3>Relational Operators</h3>
            <p>
                Relational operators compare two values and return a boolean result indicating the relation between them.
            </p>
            <div className="code-block">
                <CopyToClipboard text={relationalOperatorsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {relationalOperatorsCode.trim()}
                </SyntaxHighlighter>
            </div>

            <h3>Logical Operators</h3>
            <p>
                Logical operators are used to combine multiple conditions and determine the outcome based on whether the conditions are true or false.
            </p>
            <div className="code-block">
                <CopyToClipboard text={logicalOperatorsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {logicalOperatorsCode}
                </SyntaxHighlighter>
            </div>

            <h3>Bitwise Operators</h3>
            <p>
                Bitwise operators manipulate bits of a variable directly. They are used in low-level programming and for specific optimization tasks.
            </p>
            <div className="code-block">
                <CopyToClipboard text={bitwiseOperatorsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {bitwiseOperatorsCode}
                </SyntaxHighlighter>
            </div>

            <h3>Assignment Operators</h3>
            <p>
                Assignment operators are used to assign values to variables and modify their values based on arithmetic operations.
            </p>
            <div className="code-block">
                <CopyToClipboard text={assignmentOperatorsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {assignmentOperatorsCode}
                </SyntaxHighlighter>
            </div>

            <h3>Ternary Operator</h3>
            <p>
                The ternary operator is a shorthand way of writing an if-else statement. It returns one of two values based on a condition.
            </p>
            <div className="code-block">
                <CopyToClipboard text={ternaryOperatorCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {ternaryOperatorCode}
                </SyntaxHighlighter>
            </div>

            <h3>Summary</h3>
            <p>
                Operators are fundamental building blocks of any programming language. They allow you to manipulate variables and make decisions based on conditions. Understanding and using operators effectively is essential for writing efficient and readable code.
            </p>
            <QuestionProcessor context='Java Operators'/>
        </div>
    );
};

export default Lesson2_S2_Operators;
