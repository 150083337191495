import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const encapsulationAbstractionCode = `
  // Encapsulation and Abstraction in Java

  // Encapsulation: Use private access modifiers to hide data
  class Employee {
      private String name;
      private int salary;

      // Constructor
      public Employee(String name, int salary) {
          this.name = name;
          this.salary = salary;
      }

      // Getter methods
      public String getName() {
          return name;
      }

      public int getSalary() {
          return salary;
      }

      // Abstraction: Hide implementation details using methods
      public void displayInfo() {
          System.out.println("Employee: " + name);
          System.out.println("Salary: $" + salary);
      }
  }

  public class EncapsulationAbstractionExample {
      public static void main(String[] args) {
          // Create object of Employee class
          Employee emp = new Employee("John Doe", 50000);
          
          // Accessing private variables using getter methods
          System.out.println("Name: " + emp.getName());
          System.out.println("Salary: $" + emp.getSalary());
          
          // Calling abstraction method
          emp.displayInfo();
      }
  }
`;

const Lesson3Subtopic4 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">3.4: Encapsulation and Abstraction</h4>
            <p>
                Encapsulation and abstraction are two fundamental principles in object-oriented programming (OOP). Encapsulation hides the internal state of objects and restricts direct access, while abstraction hides the complex implementation details and shows only the necessary features of an object.
            </p>

            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {encapsulationAbstractionCode.trim()}
                </SyntaxHighlighter>
                <button className="copy-button" onClick={() => handleCopy(encapsulationAbstractionCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                This Java example demonstrates encapsulation and abstraction: the `Employee` class encapsulates data (`name` and `salary`) using private access modifiers and provides abstraction using getter methods and a display method.
            </p>
            <QuestionProcessor context='encapsulation abstraction code' />
        </div>
    );
};

export default Lesson3Subtopic4;
