import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Java code example for the while loop
const whileLoopCode = `
  // While Loop in Java

  public class WhileLoopExample {
      public static void main(String[] args) {
          // Example 1: Basic while loop
          int count = 1;
          while (count <= 5) {
              System.out.println("Count is: " + count);
              count++;
          }

          // Example 2: While loop with condition at the end
          int number = 10;
          do {
              System.out.println("Number is: " + number);
              number--;
          } while (number > 0);
      }
  }
`;

const Lesson2Subtopic4_2 = () => {
    // Function to handle copying code to clipboard
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.4.2: While Loop</h4>
            <p>
                The <code>while</code> loop in Java is used to iterate a block of code repeatedly until a given condition is true. It checks the condition before executing the loop body.
            </p>

            <div className="code-block">
                {/* SyntaxHighlighter component to display Java code */}
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {whileLoopCode.trim()}
                </SyntaxHighlighter>
                {/* Button to copy code to clipboard */}
                <CopyToClipboard text={whileLoopCode.trim()}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
            </div>

            <p>
                This Java example demonstrates different uses of the <code>while</code> loop: basic counting and executing until a condition is no longer met.
            </p>

            {/* Component for processing questions related to the lesson */}
            <QuestionProcessor context='Java While Loop'/>
        </div>
    );
};

export default Lesson2Subtopic4_2;
