import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const variablesDataTypesCode = `
  // Variables and Data Types in Java

  public class VariablesDataTypesExample {
      public static void main(String[] args) {
          // Primitive data types
          int age = 30; // Integer: stores whole numbers
          float price = 3.14f; // Float: stores floating point numbers
          double salary = 2500.50; // Double: stores larger floating point numbers
          char gender = 'M'; // Character: stores a single character
          boolean isStudent = true; // Boolean: stores true or false values

          // Reference data types
          String name = "John Doe"; // String: stores a sequence of characters

          // Output variables
          System.out.println("Age: " + age);
          System.out.println("Price: $" + price);
          System.out.println("Salary: $" + salary);
          System.out.println("Gender: " + gender);
          System.out.println("Is Student: " + isStudent);
          System.out.println("Name: " + name);
      }
  }
`;

const Lesson2Subtopic1 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.1: Variables and Data Types</h4>
            <p>
                Variables in Java are containers used to store data values. Java has several data types that define the size and type of data that can be stored in variables.
            </p>

            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {variablesDataTypesCode.trim()}
                </SyntaxHighlighter>
                <button className="copy-button" onClick={() => handleCopy(variablesDataTypesCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                This Java example demonstrates variables and data types: integers (<code>int</code>), floating-point numbers (<code>float</code> and <code>double</code>), characters (<code>char</code>), booleans (<code>boolean</code>), and strings (<code>String</code>).
            </p>

            <h5 className="subtopic-header">Java Data Types:</h5>
            <ul>
                <li><strong>int:</strong> Used to store whole numbers, such as 5, 10, -100.</li>
                <li><strong>float:</strong> Used to store floating-point numbers with smaller precision, such as 3.14, 2.5.</li>
                <li><strong>double:</strong> Used to store floating-point numbers with larger precision, such as 2500.50, 3.14159.</li>
                <li><strong>char:</strong> Used to store single characters, such as 'A', 'b', '$'.</li>
                <li><strong>boolean:</strong> Used to store true or false values, such as true, false.</li>
                <li><strong>String:</strong> Used to store sequences of characters, such as "Hello, World!".</li>
            </ul>

            <QuestionProcessor context='Java Variables and Data Types' />
        </div>
    );
};

export default Lesson2Subtopic1;
