// src/components/Examples/Lessons/IntroductionToJava.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const IntroductionToJava = () => {
    // Example code for Hello World program
    const helloWorldCode = `// Hello World program in Java
public class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, World!"); // Print Hello, World!
    }
}`;

    // Example code for Java program structure
    const javaProgramStructureCode = `// Java program structure
public class Main {
    // Entry point method
    public static void main(String[] args) {
        // Program statements go here
    }
}`;

    // Example code for Java variables and data types
    const variablesAndDataTypesCode = `// Variables and Data Types in Java
public class Main {
    public static void main(String[] args) {
        // Variables
        int number = 10;
        double decimalNumber = 10.5;
        String text = "Hello, Java!";
        
        // Output
        System.out.println(number);
        System.out.println(decimalNumber);
        System.out.println(text);
    }
}`;

    // Example code for Java operators
    const operatorsCode = `// Operators in Java
public class Main {
    public static void main(String[] args) {
        // Arithmetic Operators
        int a = 10;
        int b = 5;
        int sum = a + b; // Addition
        
        // Output
        System.out.println(sum);
    }
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">1: Introduction to Java</h2>
            <p>
                Java is a widely used programming language known for its portability, security, and robustness. It is commonly used for developing desktop, web, and mobile applications, as well as large-scale enterprise systems.
            </p>

            <h3>Getting Started with Java</h3>
            <p>
                Java programs are written, compiled, and executed using the Java Development Kit (JDK), which includes the Java Runtime Environment (JRE) and the Java compiler. Follow these steps to set up Java:
            </p>
            <ul>
                <li>
                    Download and install the latest JDK from the&nbsp;
                    <a href="https://www.oracle.com/java/technologies/javase-downloads.html" target="_blank" rel="noopener noreferrer">
                        official Oracle website
                    </a>.
                </li>
                <li>Set up the environment variables (<code>JAVA_HOME</code> and <code>PATH</code>) as instructed in the JDK installation guide.</li>
                <li>Verify the installation by running <code>java -version</code> and <code>javac -version</code> in your command prompt or terminal.</li>
            </ul>

            <h3>Hello World Program</h3>
            <p>
                The "Hello, World!" program is typically the first program you write when learning a new programming language. It simply prints "Hello, World!" to the console.
            </p>
            <div className="code-block">
                <CopyToClipboard text={helloWorldCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {helloWorldCode}
                </SyntaxHighlighter>
            </div>

            <h3>Java Program Structure</h3>
            <p>
                All Java programs consist of one or more classes. Each class contains methods, which are blocks of code that perform tasks. The main method is the entry point for all Java programs.
            </p>
            <div className="code-block">
                <CopyToClipboard text={javaProgramStructureCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {javaProgramStructureCode}
                </SyntaxHighlighter>
            </div>

            <h3>Variables and Data Types</h3>
            <p>
                Java is a statically typed language, which means you must declare the type of each variable before using it. Java supports various data types, including integers, floating-point numbers, characters, and strings.
            </p>
            <div className="code-block">
                <CopyToClipboard text={variablesAndDataTypesCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {variablesAndDataTypesCode}
                </SyntaxHighlighter>
            </div>

            <h3>Operators in Java</h3>
            <p>
                Operators are special symbols that perform operations on operands. Java supports several types of operators, including arithmetic, relational, logical, bitwise, and assignment operators.
            </p>
            <div className="code-block">
                <CopyToClipboard text={operatorsCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {operatorsCode}
                </SyntaxHighlighter>
            </div>

            <h3>Summary</h3>
            <p>
                Java is a powerful and versatile programming language used for a wide range of applications. Learning Java fundamentals, such as syntax, data types, and operators, is essential for mastering the language and building complex software systems.
            </p>
            <QuestionProcessor context='Introduction into Java' />
        </div>
    );
};

export default IntroductionToJava;
