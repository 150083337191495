import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import HelloWorldImage1 from '../../../img/HelloWorld.PNG'
import HelloWorldImage2 from '../../../img/HelloWorld2.PNG'
import HelloWorldImage3 from '../../../img/HelloWorld3.PNG'
import HelloWorldImage4 from '../../../img/HelloWorld4.PNG'
import HelloWorldImage5 from '../../../img/HelloWorld5.PNG'
import QuestionProcessor from './QuestionProcesser';

const helloWorldCode = `
  // Hello, World! program in Java

  public class HelloWorld {
      public static void main(String[] args) {
          System.out.println("Hello, World!");
      }
  }
`;

const Slideshow = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    };

    return (
        <div className="slideshow-container">
            <div className="mySlides fade">
                <img src={images[currentSlide]} style={{ width: '100%' }} alt={`Slide ${currentSlide + 1}`} />
            </div>

            <a className="prev" onClick={prevSlide}>&#10094; Prev</a>
            <a className="next" onClick={nextSlide}>Next &#10095;</a>
        </div>
    );
};

const Lesson1Subtopic3 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">1.3: Hello, World! Your First Program</h4>
            <p>
                In this lesson, we will guide you through creating and running your first "Hello, World!" program in Java using Eclipse IDE.
            </p>

            <h5 className="subtopic-header">Step 1: Open Eclipse IDE</h5>
            <p>
                First, open Eclipse IDE. If you haven't installed Eclipse yet, refer to <a href="#">Lesson 1, Subtopic 2: Setting Up Java</a> for installation instructions.
            </p>

            <h5 className="subtopic-header">Step 2: Create a New Java Project</h5>
            <ol>
                <li>Click on <strong>File</strong> &gt; <strong>New</strong> &gt; <strong>Java Project</strong>.</li>
                <li>Enter a <strong>Project name</strong>, such as "HelloWorldProject".</li>
                <li>Click <strong>Finish</strong>.</li>
            </ol>

            <Slideshow images={[HelloWorldImage1, HelloWorldImage2]} />

            <h5 className="subtopic-header">Step 3: Create a New Java Class</h5>
            <ol>
                <li>Right-click on your newly created project and select <strong>New</strong> &gt; <strong>Class</strong>.</li>
                <li>Enter a <strong>Name</strong> for your class, such as "HelloWorld".</li>
                <li>Check the box for <strong>public static void main(String[] args)</strong>.</li>
                <li>Click <strong>Finish</strong>.</li>
            </ol>
            <Slideshow images={[HelloWorldImage3, HelloWorldImage4, HelloWorldImage5]} />

            <h5 className="subtopic-header">Step 4: Write the "Hello, World!" Program</h5>
            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {helloWorldCode.trim()}
                </SyntaxHighlighter>
                <button className="copy-button" onClick={() => handleCopy(helloWorldCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                The above Java program uses the <code>System.out.println()</code> statement to print "Hello, World!" to the console.
            </p>

            <h5 className="subtopic-header">Step 5: Run the Program</h5>
            <ol>
                <li>Right-click on the Java file (HelloWorld.java) in the Package Explorer.</li>
                <li>Select <strong>Run As</strong> &gt; <strong>Java Application</strong>.</li>
                <li>Observe the output "Hello, World!" in the Console view at the bottom of the Eclipse IDE.</li>
            </ol>

            <p>
                Congratulations! You have successfully created and run your first Java program using Eclipse IDE.
            </p>
            <QuestionProcessor context='Hello world in java'/>
        </div>
    );
};

export default Lesson1Subtopic3;