import React, { useState } from 'react';
import './AquariumSidebar.css';

function AquariumSidebar({ setLesson, setSubtopic }) {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="sidebar-container">
            <button className="hamburger" onClick={toggleSidebar}>
                &#9776;
            </button>
            <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
                <ul className="sidebar-menu">
                    <li>
                        <button onClick={() => { setLesson('Lesson 1'); setSubtopic(null); }}>Build-out Phases</button>
                        <ul className="sub-menu">
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 1'); }}>1: Phase #1 - Simple Sensors</button></li>
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 2'); }}>3: Phase #2 - Data Analyisis & Visualization</button></li>
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 3'); }}>4: Phase #3 - Active Measures</button></li>
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 4'); }}>5: Phase #4 - Conclusion</button></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AquariumSidebar;
