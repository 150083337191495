import React, { useState } from 'react';
import './CourseSidebar.css';

function CourseSidebar({ setLesson, setSubtopic }) {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="sidebar-container">
            <button className="hamburger" onClick={toggleSidebar}>
                &#9776;
            </button>
            <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
                <ul className="sidebar-menu">
                    <li>
                        <button onClick={() => { setLesson('Lesson 1'); setSubtopic(null); }}>Lesson 1: Introduction to Java</button>
                        <ul className="sub-menu">
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 1'); }}>1: History of Java</button></li>
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 2'); }}>2: Setting Up Java</button></li>
                            <li><button onClick={() => { setLesson('Lesson 1'); setSubtopic('Subtopic 3'); }}>3: Hello World: Creating Your Own Program</button></li>
                        </ul>
                    </li>
                    <li>
                        <button onClick={() => { setLesson('Lesson 2'); setSubtopic(null); }}>Lesson 2: Java Basics</button>
                        <ul className="sub-menu">
                            <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 1'); }}>1: Variables and Data Types</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 1.1'); }}>1.1 Quiz #1</button></li>
                                </ul>
                            </li>
                            <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 2'); }}>2: Operators</button></li>
                            <ul className="sub-sub-menu">
                                <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 2.1'); }}>1.2 Quiz #2</button></li>
                            </ul>
                            <li>
                                <button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 3'); }}>3: Conditional Statements</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 3.1'); }}>3.1: If-Else Statements</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 3.2'); }}>3.2: Switch Statements</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 3.3'); }}>3.3: Quiz #3</button></li>
                                </ul>
                            </li>
                            <li>
                                <button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 4'); }}>4: Loops</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 4.1'); }}>4.1: For Loop</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 4.2'); }}>4.2: While Loop</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 4.3'); }}>4.3: Quiz #4</button></li>
                                </ul>
                            </li>
                            <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5'); }}>5: Lists and Arrays</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5.1'); }}>5.1: Array</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5.2'); }}>5.2: Arraylist</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5.3'); }}>5.3: LinkedList</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5.4'); }}>5.4: Other List Implementations</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5.5'); }}>5.5: Quiz #5</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 5.6'); }}>5.6 Challange #1</button></li>
                                </ul>
                            </li>
                            <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 6'); }}>6: I/O Streams</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 6.1'); }}>6.1 Quiz #6</button></li>
                                </ul>
                            </li>
                            <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 7'); }}>7: Exception Handling</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 2'); setSubtopic('Subtopic 7.1'); }}>7.1 Quiz #7</button></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <button onClick={() => { setLesson('Lesson 3'); setSubtopic(null); }}>Lesson 3: Object-Oriented Programming</button>
                        <ul className="sub-menu">
                            <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 1'); }}>1: Classes and Objects</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 1.1'); }}>1.1 Quiz #8</button></li>
                                </ul>
                            </li>
                            <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 2'); }}>2: Methods</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 2.1'); }}>2.1 Quiz #9</button></li>
                                </ul>
                            </li>
                            <li>
                                <button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 3'); }}>3: Inheritance and Polymorphism</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 3.1'); }}>3.1: Inheritance</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 3.2'); }}>3.2: Polymorphism</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 3.3'); }}>3.3 Quiz #10</button></li>
                                </ul>
                            </li>
                            <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 4'); }}>4: Encapsulation and Abstraction</button>
                                <ul className="sub-sub-menu">
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 4.1'); }}>4.1 Quiz #11</button></li>
                                    <li><button onClick={() => { setLesson('Lesson 3'); setSubtopic('Subtopic 4.2'); }}>Challange #2</button></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><button onClick={() => { setLesson('Lesson 4'); setSubtopic(null); }}>Reference Material</button></li>
                </ul>
            </div>
        </div>
    );
}

export default CourseSidebar;
