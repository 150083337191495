import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const classesAndObjectsCode = `
  // Classes and Objects in Java

  // Define a class
  class Car {
      // Fields
      String brand;
      String model;
      int year;

      // Constructor
      public Car(String brand, String model, int year) {
          this.brand = brand;
          this.model = model;
          this.year = year;
      }

      // Method to display car information
      public void displayInfo() {
          System.out.println("Car: " + brand + " " + model + " (" + year + ")");
      }
  }

  public class ClassesAndObjectsExample {
      public static void main(String[] args) {
          // Create objects of the Car class
          Car car1 = new Car("Toyota", "Corolla", 2020);
          Car car2 = new Car("Honda", "Civic", 2021);

          // Call method to display car information
          car1.displayInfo();
          car2.displayInfo();
      }
  }
`;

const Lesson3Subtopic1 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">3.1: Classes and Objects</h4>
            <p>
                In Java, a class is a blueprint for creating objects. An object is an instance of a class that encapsulates data (fields) and behavior (methods).
            </p>

            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {classesAndObjectsCode.trim()}
                </SyntaxHighlighter>
                <button className="copy-button" onClick={() => handleCopy(classesAndObjectsCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                This Java example demonstrates the creation of a `Car` class with fields for brand, model, and year, and the instantiation of objects based on this class.
            </p>
            <QuestionProcessor context='Java classes and objects'/>
        </div>
    );
};

export default Lesson3Subtopic1;
