import React, { useState, useEffect } from 'react';
import './Aquarium.css';

const SensorCard = ({ sensorName, sensorValue, currentValue, previousValue, staticText }) => {
    const [highlightColor, setHighlightColor] = useState(null);

    useEffect(() => {
        if (currentValue > previousValue) {
            setHighlightColor('green');
        } else if (currentValue < previousValue) {
            setHighlightColor('red');
        }

        // Reset highlight after 1 second
        const timeout = setTimeout(() => {
            setHighlightColor(null);
        }, 1500);

        return () => clearTimeout(timeout);
    }, [currentValue, previousValue]);

    // Function to format string values representing percent
    const formatFloat = (value) => {
        // Check if the string contains a decimal point
        const decimalIndex = value.indexOf('.');

        if (decimalIndex !== -1) {
            // Extract the substring up to two decimal places
            const truncatedValue = value.substring(0, decimalIndex + 3); // Include two decimal places
            return `${truncatedValue}`; // Append %
        } else {
            // Append ".00" if no decimal point found
            return `${value}.00`; // Append %
        }
    };

    let displayValue = "";
    if (sensorName.includes('Temperature')) {
        displayValue = formatFloat(currentValue) + "&#176;C"; // Use &#176; for degree symbol
    }
    else if (sensorName.includes('Humidity')) {
        displayValue = formatFloat(currentValue) + " %";
    }
    else if (sensorName.includes('Light Sensor')) {
        displayValue = formatFloat(currentValue) + " Lux";
    }
    else {
        displayValue = currentValue;
    }

    return (
        <div className={`sensor-card ${highlightColor ? `highlight-${highlightColor}` : ''}`}>
            <h5>{sensorName}</h5>
            <p dangerouslySetInnerHTML={{ __html: displayValue }}></p> {/* Render HTML content */}
            <p className="static-text" dangerouslySetInnerHTML={{ __html: staticText }}></p>
        </div>
    );
};

export default SensorCard;
