import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const ObjectOrientedProgramming = () => {
    // Example code for Class and Object
    const classAndObjectCode = `// Class and Object in Java
public class Main {
    // Class
    static class Car {
        // Attributes
        String brand;
        int year;
        
        // Constructor
        public Car(String brand, int year) {
            this.brand = brand;
            this.year = year;
        }
        
        // Method
        public void displayInfo() {
            System.out.println("Car brand: " + brand + ", year: " + year);
        }
    }

    // Main method
    public static void main(String[] args) {
        // Create an object of Car
        Car myCar = new Car("Toyota", 2020);
        
        // Call method from Car class
        myCar.displayInfo();
    }
}`;

    // Example code for Method
    const methodCode = `// Method in Java
public class Main {
    // Method
    static void sayHello(String name) {
        System.out.println("Hello, " + name);
    }

    // Main method
    public static void main(String[] args) {
        // Call method
        sayHello("John");
    }
}`;

    // Example code for Constructor
    const constructorCode = `// Constructor in Java
public class Main {
    // Class
    static class Car {
        // Attributes
        String brand;
        int year;
        
        // Constructor
        public Car(String brand, int year) {
            this.brand = brand;
            this.year = year;
        }
        
        // Method
        public void displayInfo() {
            System.out.println("Car brand: " + brand + ", year: " + year);
        }
    }

    // Main method
    public static void main(String[] args) {
        // Create an object of Car
        Car myCar = new Car("Toyota", 2020);
        
        // Call method from Car class
        myCar.displayInfo();
    }
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">3: Object-Oriented Programming in Java</h2>

            <h3>Class and Object</h3>
            <p>
                In Java, a class is a blueprint for creating objects. An object is an instance of a class. Classes define the properties and behaviors common to all objects of a certain kind.
            </p>
            <div className="code-block">
                <CopyToClipboard text={classAndObjectCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {classAndObjectCode}
                </SyntaxHighlighter>
            </div>

            <h3>Method</h3>
            <p>
                A method is a collection of statements that perform a specific task. It is similar to a function in procedural programming languages. Methods are defined within a class and are called to perform their tasks.
            </p>
            <div className="code-block">
                <CopyToClipboard text={methodCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {methodCode}
                </SyntaxHighlighter>
            </div>

            <h3>Constructor</h3>
            <p>
                A constructor is a special method that is automatically called when an object is created. It initializes the object and may have parameters that are used to set the initial state of the object.
            </p>
            <div className="code-block">
                <CopyToClipboard text={constructorCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {constructorCode}
                </SyntaxHighlighter>
            </div>
            <QuestionProcessor context='Java OOP overview'/>
        </div>
    );
};

export default ObjectOrientedProgramming;
