// src/components/Examples/Lessons/Lesson2_S5_1_ArrayList.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const Lesson2_S5_2_ArrayList = () => {
    // Example code for ArrayList declaration and initialization
    const arrayListExampleCode = `// ArrayList declaration and initialization
import java.util.ArrayList;

public class Main {
    public static void main(String[] args) {
        // Declare ArrayList of integers
        ArrayList<Integer> numbers = new ArrayList<>();

        // Add elements to ArrayList
        numbers.add(1);
        numbers.add(2);
        numbers.add(3);
        numbers.add(4);
        numbers.add(5);

        // Print ArrayList elements
        System.out.println(numbers);
    }
}`;

    // Example code for iterating through an ArrayList
    const iterateArrayListCode = `// Iterating through an ArrayList
for (Integer number : numbers) {
    System.out.println(number); // Print each element
}`;

    // Example code for removing an element from an ArrayList
    const removeElementCode = `// Removing an element from an ArrayList
numbers.remove(2); // Remove element at index 2
System.out.println(numbers); // Print ArrayList after removal`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.5.2: ArrayList</h2>
            <p>
                ArrayList is a dynamic data structure in Java that provides resizable arrays. It implements the List interface and allows you to add, remove, and access elements based on their index.
            </p>

            <h3>ArrayList Declaration and Initialization</h3>
            <p>
                ArrayLists are declared with a specific type and can be initialized with values.
            </p>
            <div className="code-block">
                <CopyToClipboard text={arrayListExampleCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {arrayListExampleCode}
                </SyntaxHighlighter>
            </div>

            <h3>Iterating Through an ArrayList</h3>
            <p>
                You can iterate through an ArrayList using an enhanced for loop.
            </p>
            <div className="code-block">
                <CopyToClipboard text={iterateArrayListCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {iterateArrayListCode}
                </SyntaxHighlighter>
            </div>

            <h3>Removing an Element from an ArrayList</h3>
            <p>
                You can remove elements from an ArrayList by specifying the index or the element itself.
            </p>
            <div className="code-block">
                <CopyToClipboard text={removeElementCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {removeElementCode}
                </SyntaxHighlighter>
            </div>

            <h3>Summary</h3>
            <p>
                ArrayLists provide a flexible way to work with collections of elements in Java. They automatically resize themselves as elements are added or removed, making them suitable for dynamic applications.
            </p>

            <QuestionProcessor context='Java arrayLists'/>
        </div>
    );
};

export default Lesson2_S5_2_ArrayList;
