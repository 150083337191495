import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const methodsCode = `
  // Methods in Java

  // Define a class
  public class MethodsExample {
      // Method without parameters
      public static void greet() {
          System.out.println("Hello, welcome to Java methods!");
      }

      // Method with parameters and return type
      public static int addNumbers(int a, int b) {
          return a + b;
      }

      public static void main(String[] args) {
          // Calling a method without parameters
          greet();

          // Calling a method with parameters
          int sum = addNumbers(5, 3);
          System.out.println("Sum of 5 and 3 is: " + sum);
      }
  }
`;

const Lesson3Subtopic2 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">3.2: Methods</h4>
            <p>
                In Java, methods are blocks of code that perform a specific task. They are defined within classes and can be called to execute their functionality.
            </p>

            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {methodsCode.trim()}
                </SyntaxHighlighter>
                <button className="copy-button" onClick={() => handleCopy(methodsCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                This Java example demonstrates the definition and usage of methods: a method without parameters (`greet`) and a method with parameters (`addNumbers`).
            </p>
            <QuestionProcessor context='Java methods'/>
        </div>
    );
};

export default Lesson3Subtopic2;
