import React, { useState, useEffect } from 'react';
import './QuizComponents.css';

const QuizMultipleChoice = ({ question, options, questionID, onChange, selectedAnswer }) => {
    const [internalSelectedAnswer, setInternalSelectedAnswer] = useState(null);

    useEffect(() => {
        setInternalSelectedAnswer(selectedAnswer); // Sync internal state with selected answer prop
    }, [selectedAnswer]);

    const handleOptionChange = (option) => {
        setInternalSelectedAnswer(option);
        onChange(questionID, option);
    };

    return (
        <div className="activity">
            <br />
            <p className="question"><strong>{questionID}.</strong> {question}</p>
            <div className="activity-text">
                {options.map((option, index) => (
                    <div key={index} className="option">
                        <input
                            type="radio"
                            id={`option-${index}`}
                            name={`answer-${questionID}`}
                            value={option}
                            checked={internalSelectedAnswer === option}
                            onChange={() => handleOptionChange(option)}
                        />
                        <label htmlFor={`option-${index}`}>{option}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuizMultipleChoice;
