import React, { useState } from 'react';

const Slideshow = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    };

    return (
        <div className="slideshow-container">
            <div className="mySlides fade">
                <img src={images[currentSlide]} style={{ width: '100%' }} alt={`Slide ${currentSlide + 1}`} />
            </div>

            <a className="prev" onClick={prevSlide}>&#10094; Prev</a>
            <a className="next" onClick={nextSlide}>Next &#10095;</a>
        </div>
    );
};

export default Slideshow;
