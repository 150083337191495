import React, { useState } from 'react';
import axios from 'axios';
import './Lessons.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import parse from 'html-react-parser';

const SendUsersQuestion = async (question, setAnswer, setIsQuerying, context) => {
    setIsQuerying(true); // Set querying state to true

    try {
        const response = await axios.get('https://45.77.158.54:5000/api/ask_question', {
            params: {
                question,
                context
            }
        });

        setAnswer(response.data); // Set the entire API response as the answer
    } catch (error) {
        console.error('Error fetching answer from server:', error);
        setAnswer('<p>Sorry, there was an error processing your question. Please try again.</p>');
    } finally {
        setIsQuerying(false); // Set querying state to false after request completes
    }
};

const QuestionProcessor = ({context}) => {
    const [answer, setAnswer] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [isQuerying, setIsQuerying] = useState(false); // State to track if querying

    const handleQuestionChange = (e) => {
        setQuestionText(e.target.value);
    };

    const handleGetAnswer = () => {
        setAnswer(''); // Clear previous answer
        setIsQuerying(true); // Set querying state to true

        SendUsersQuestion(questionText, setAnswer, setIsQuerying, context);
    };

    const handleClearAll = () => {
        setQuestionText('');
        setAnswer('');
    };

    // Function to parse and extract Java code from the API response
    // eslint-disable-next-line no-unused-vars
    const extractJavaCode = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const codeElements = doc.querySelectorAll('codeHere');
        const javaCodeArray = [];

        codeElements.forEach((codeElement) => {
            javaCodeArray.push(codeElement.innerHTML.trim()); // Use innerHTML to retain the HTML structure inside <codeHere>
        });

        return javaCodeArray;
    };

    // Function to render HTML content using html-react-parser
    const renderHtmlContent = (htmlString) => {
        const options = {
            replace: (domNode) => {
                if (domNode.name === 'codeHere') {
                    // Replace <codeHere> with SyntaxHighlighter
                    return (
                        <SyntaxHighlighter language="java" style={atomOneLight}>
                            {domNode.children[0].data.trim()}
                        </SyntaxHighlighter>
                    );
                }
                // Default return to render other HTML tags
                return domNode;
            }
        };

        return parse(htmlString, options);
    };

    // Render the component
    return (
        <div className="QP-content">
            <br />
            <h4 className="subtopic-header">Questions?</h4>
            <p>If you have any questions about the course you can ask them below.</p>
            <textarea
                id="default"
                className="QP-TextArea"
                value={questionText}
                onChange={handleQuestionChange}
                placeholder="Type your question here..."
            ></textarea>
            <button
                className="QP-AnswerButton"
                onClick={handleGetAnswer}
                disabled={isQuerying} // Disable button when querying
            >
                Get Answer
            </button>
            <button
                className="QP-AnswerButton"
                onClick={handleClearAll}
                disabled={isQuerying} // Disable button when querying
            >
                Clear
            </button>
            {isQuerying && (
                <p>Querying an answer...</p>
            )}
            {answer && !isQuerying && (
                <div className="QP-Answer">
                    <h4>Answer:</h4>
                    {/* Render HTML content */}
                    {renderHtmlContent(answer)}
                </div>
            )}
            <br></br>
            <br></br>
        </div>
    );
};

export default QuestionProcessor;