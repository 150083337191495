import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Demos from './pages/Demos';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

function App() {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/demos" element={<Demos />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/contact" element={<Contact />} />

                    <Route path="/java-course/*" element={<Demos />} />
                    <Route path="/movie-search" element={<Demos />} />
                    <Route path="/minecraft-map-pack" element={<Demos />} />
                    <Route path="/flash-matching-activity" element={<Demos />} />
                    <Route path="/aquarium-automation" element={<Demos />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
