import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const JavaBasics = () => {
    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">Lesson 2: Java Basics</h2>

            <h3>Introduction to Java</h3>
            <p>
                Java is a widely-used, high-level programming language developed by Sun Microsystems (now owned by Oracle). It was designed to be platform-independent, meaning that it can run on any device that has a Java Virtual Machine (JVM).
            </p>

            <h3>Key Features of Java</h3>
            <ul>
                <li>Object-Oriented: Java follows an object-oriented programming paradigm where everything is treated as an object.</li>
                <li>Platform-Independent: Java programs can run on any device that has a JVM, making it highly portable.</li>
                <li>Simple and Easy to Learn: Java was designed to be beginner-friendly and has a clear, concise syntax.</li>
                <li>Secure: Java is designed with security features to protect against viruses and other malicious attacks.</li>
                <li>Robust: Java is reliable and can handle errors during runtime.</li>
                <li>Multithreaded: Java supports concurrent execution of multiple threads.</li>
            </ul>

            <h3>Java Development Environment</h3>
            <p>
                To start programming in Java, you need to set up a development environment. This typically involves installing the Java Development Kit (JDK) and an Integrated Development Environment (IDE) like Eclipse or IntelliJ IDEA.
            </p>

            <h3>Basic Syntax</h3>
            <p>
                Java syntax is similar to C and C++, making it easy for developers from these languages to learn Java. It includes:
            </p>
            <ul>
                <li>Variables and Data Types</li>
                <li>Operators</li>
                <li>Control Structures (if, else, switch, for, while, etc.)</li>
                <li>Classes and Objects</li>
                <li>Methods</li>
                <li>Constructors</li>
            </ul>

            <h3>Object-Oriented Programming</h3>
            <p>
                Java is a fully object-oriented programming language. This means that everything in Java is an object, which has attributes (fields) and behaviors (methods). Key concepts include:
            </p>
            <ul>
                <li>Classes and Objects</li>
                <li>Inheritance</li>
                <li>Polymorphism</li>
                <li>Encapsulation</li>
                <li>Abstraction</li>
            </ul>

            <h3>Conclusion</h3>
            <p>
                Understanding the basics of Java programming is crucial for building robust applications. In the following lessons, we will delve deeper into each of these concepts and learn how to write and execute Java programs.
            </p>
            <QuestionProcessor context='Java Basics'/>
        </div>
    );
};

export default JavaBasics;
