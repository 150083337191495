// src/components/Examples/Lessons/Lesson2_S5_1_Arrays.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const Lesson2_S5_1_Arrays = () => {
    // Example code for array declaration and initialization
    const arrayExampleCode = `// Array declaration and initialization
int[] numbers = new int[5]; // Declare an array of integers with size 5
numbers[0] = 1; // Assign value to the first element
numbers[1] = 2;
numbers[2] = 3;
numbers[3] = 4;
numbers[4] = 5;`;

    // Example code for iterating through an array
    const iterateArrayCode = `// Iterating through an array
for (int i = 0; i < numbers.length; i++) {
    System.out.println(numbers[i]); // Print each element
}`;

    // Example code for multidimensional arrays
    const multiDimensionalArrayCode = `// Multidimensional arrays
int[][] matrix = new int[3][3]; // 3x3 matrix
matrix[0][0] = 1;
matrix[0][1] = 2;
matrix[0][2] = 3;
matrix[1][0] = 4;
matrix[1][1] = 5;
matrix[1][2] = 6;
matrix[2][0] = 7;
matrix[2][1] = 8;
matrix[2][2] = 9;`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">2.5.1: Arrays</h2>
            <p>
                Arrays in Java are fixed-size data structures that store elements of the same type sequentially in memory.
            </p>

            <h3>Array Declaration and Initialization</h3>
            <p>
                Arrays are declared with a specific size and can be initialized with values.
            </p>
            <div className="code-block">
                <CopyToClipboard text={arrayExampleCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {arrayExampleCode}
                </SyntaxHighlighter>
            </div>

            <h3>Iterating Through an Array</h3>
            <p>
                You can iterate through an array using a for loop.
            </p>
            <div className="code-block">
                <CopyToClipboard text={iterateArrayCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {iterateArrayCode}
                </SyntaxHighlighter>
            </div>

            <h3>Multidimensional Arrays</h3>
            <p>
                Java supports multidimensional arrays, allowing you to create arrays of arrays.
            </p>
            <div className="code-block">
                <CopyToClipboard text={multiDimensionalArrayCode}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {multiDimensionalArrayCode}
                </SyntaxHighlighter>
            </div>

            <h3>Summary</h3>
            <p>
                Arrays are fundamental data structures in Java that provide efficient access to elements based on their index. Understanding arrays is essential for building more complex data structures and algorithms.
            </p>

            <QuestionProcessor context='Java Arrays'/>
        </div>
    );
};

export default Lesson2_S5_1_Arrays;
