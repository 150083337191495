import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <h1>Contact Me</h1>
            <div className="contact-details">
                <p>Email: <a href="mailto:jjlortez.jl@gmail.com">jjlortez.jl@gmail.com</a></p>
                <p>Phone: (519) 949-1718</p>
                <p>LinkedIn: <a href="https://www.linkedin.com/in/jaron-lorentz-a9b6a0271">https://www.linkedin.com/in/jaron-lorentz-a9b6a0271</a></p>
                <p>GitHub: <a href="https://github.com/JJL0rtez">https://github.com/JJL0rtez</a></p>
                <p>Location: St. Marys, Canada</p>
            </div>
        </div>
    );
};

export default Contact;
