// src/components/Examples/Lessons/Lesson3_S3_1_Inheritance.js

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css';
import QuestionProcessor from './QuestionProcesser';

const Lesson3_S3_1_Inheritance = () => {
    const codeString = `// Parent class (superclass)
class Vehicle {
    String brand;

    // Constructor
    public Vehicle(String brand) {
        this.brand = brand;
    }

    // Method
    public void displayInfo() {
        System.out.println("Vehicle brand: " + brand);
    }
}

// Child class (subclass)
class Car extends Vehicle {
    int year;

    // Constructor
    public Car(String brand, int year) {
        super(brand); // Call superclass constructor
        this.year = year;
    }

    // Method overriding
    @Override
    public void displayInfo() {
        System.out.println("Car brand: " + brand + ", year: " + year);
    }
}

public class InheritanceExample {
    public static void main(String[] args) {
        // Create an object of Car
        Car myCar = new Car("Toyota", 2020);
        
        // Call method from Car class
        myCar.displayInfo();
    }
}`;

    return (
        <div className="lesson-content">
            <h2 className="subtopic-header">3.3.1: Inheritance</h2>
            <p>
                Inheritance is a fundamental concept in object-oriented programming (OOP) that allows one class to inherit the properties and methods of another class. This promotes code reusability, logical hierarchy, and the ability to create more complex objects by building upon existing ones.
            </p>
            <h3>What is Inheritance?</h3>
            <p>
                Inheritance allows a new class (subclass or child class) to inherit fields and methods from an existing class (superclass or parent class). The subclass can add its own fields and methods, or override the methods of the superclass to provide specific implementations.
            </p>
            <h3>Why is Inheritance Important?</h3>
            <ul>
                <li><b>Code Reusability:</b> Inheritance allows developers to create new classes based on existing classes, reducing redundancy and promoting code reuse.</li>
                <li><b>Logical Hierarchy:</b> It helps in organizing and structuring the code in a hierarchical manner, making it easier to understand and maintain.</li>
                <li><b>Method Overriding:</b> Subclasses can override superclass methods to provide specific implementations, enabling polymorphism.</li>
                <li><b>Extensibility:</b> It allows for the creation of more complex classes that build upon the functionality of existing classes.</li>
            </ul>
            <h3>Example: Inheritance in Java</h3>
            <p>Let's understand inheritance through an example in Java:</p>
            <div className="code-block">
                <CopyToClipboard text={codeString}>
                    <button className="copy-button">Copy Code</button>
                </CopyToClipboard>
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {codeString}
                </SyntaxHighlighter>
            </div>
            <h3>Explanation of the Example</h3>
            <p>Here is a breakdown of the example code to understand how inheritance works in practice:</p>
            <ul>
                <li><b>Vehicle Class:</b> This is the superclass with a field <code>brand</code>, a constructor to initialize it, and a method <code>displayInfo</code> that prints the vehicle's brand.</li>
                <li><b>Car Class:</b> This is the subclass that extends <code>Vehicle</code>. It inherits the <code>brand</code> field and <code>displayInfo</code> method. The <code>Car</code> class adds an additional field <code>year</code>, a constructor that calls the superclass constructor using <code>super(brand)</code>, and overrides the <code>displayInfo</code> method to include the year of the car.</li>
                <li><b>InheritanceExample Class:</b> This class contains the <code>main</code> method where a <code>Car</code> object is created and its <code>displayInfo</code> method is called. The output will be:
                    <pre>Car brand: Toyota, year: 2020</pre>
                </li>
            </ul>
            <h3>Benefits of Inheritance</h3>
            <ul>
                <li><b>Reduction in Code Duplication:</b> By inheriting common functionality from a superclass, subclasses do not need to duplicate the code.</li>
                <li><b>Improved Code Maintenance:</b> Changes in the superclass are automatically inherited by the subclasses, making it easier to update and maintain the code.</li>
                <li><b>Polymorphism:</b> Inheritance enables polymorphism, allowing subclasses to be treated as instances of their superclass, facilitating flexible and reusable code.</li>
            </ul>
            <QuestionProcessor context='Java inheritance'/>
        </div>
    );
};

export default Lesson3_S3_1_Inheritance;
