import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './Lessons.css'; // Assuming you have a shared styles file
import QuestionProcessor from './QuestionProcesser';

const switchStatementsCode = `
  // Switch Statements in Java

  public class SwitchStatementsExample {
      public static void main(String[] args) {
          int day = 3;
          String dayName;

          // Switch statement
          switch (day) {
              case 1:
                  dayName = "Sunday";
                  break;
              case 2:
                  dayName = "Monday";
                  break;
              case 3:
                  dayName = "Tuesday";
                  break;
              case 4:
                  dayName = "Wednesday";
                  break;
              case 5:
                  dayName = "Thursday";
                  break;
              case 6:
                  dayName = "Friday";
                  break;
              case 7:
                  dayName = "Saturday";
                  break;
              default:
                  dayName = "Invalid day";
                  break;
          }

          System.out.println("The day is " + dayName);
      }
  }
`;

const Lesson2Subtopic3_2 = () => {
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                alert('Code copied to clipboard!');
            },
            (err) => {
                console.error('Failed to copy: ', err);
                alert('Failed to copy code to clipboard.');
            }
        );
    };

    return (
        <div className="lesson-content">
            <h4 className="subtopic-header">2.3.2: Switch Statements</h4>
            <p>
                Switch statements in Java are used to execute one statement from multiple conditions. They provide a more efficient way to handle multiple conditions compared to if-else statements.
            </p>

            <div className="code-block">
                <SyntaxHighlighter language="java" style={atomOneLight}>
                    {switchStatementsCode.trim()}
                </SyntaxHighlighter>
                <button className="copy-button" onClick={() => handleCopy(switchStatementsCode.trim())}>
                    Copy
                </button>
            </div>

            <p>
                This Java example demonstrates the use of switch statements to determine the name of a day based on its numeric representation.
            </p>
            <QuestionProcessor context='java switch statments'/>
        </div>
    );
};

export default Lesson2Subtopic3_2;
