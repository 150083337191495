import React, { useState, useEffect } from 'react';
import SensorCard from './SensorCard'; // Adjust the path as needed
import './Aquarium.css';
import axios from 'axios';


const SensorDataDisplay = () => {
    const [sensorData, setSensorData] = useState({
        floatSwitchState: { value: 'Status: UNKNOWN', previousValue: null, staticText: '' },
        humidity: { value: 'Humidity: --', previousValue: null, staticText: 'Target: 50-70%' },
        temperatureC_DHT22: { value: 'Temperature: -- �C', previousValue: null, staticText: 'Target: 24-27 &#176;C' },
        temperatureC_DS18B20: { value: 'Temperature: -- �C', previousValue: null, staticText: 'Target: 24-27 &#176;C' },
        tdsValue: { value: 'TDS: -- ppm', previousValue: null, staticText: 'Target: < 500 ppm' },
        pHValue: { value: 'pH: --', previousValue: null, staticText: '' },
        flowRate: { value: 'Flow Rate: -- L/min', previousValue: null, staticText: '' },
        pressure: { value: 'Pressure: -- bar', previousValue: null, staticText: '' },
        luminosity: { value: 'Luminosity: -- lux', previousValue: null, staticText: '' },
        ammoniaValue: { value: 'Ammonia: -- ppm', previousValue: null, staticText: '' },
        dissolvedOxygen: { value: 'Dissolved Oxygen: -- mg/L', previousValue: null, staticText: '' },
    });

    useEffect(() => {
       

        const fetchData = async () => {
            try {
                const response = await fetch('https://45.77.158.54/api/data', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch sensor data: ${response.statusText}`);
                }

                const data = await response.json();
                console.log(data);

                setSensorData(prevState => ({
                    floatSwitchState: {
                        value: `Status: ${data.floatSwitchState === 1 ? 'Water level OK' : 'Water Level Low'}`,
                        previousValue: prevState.floatSwitchState.value,
                        staticText: prevState.floatSwitchState.staticText,
                    },
                    humidity: {
                        value: `Humidity: ${formatFloat(data.humidity_DHT22)}%`,
                        previousValue: prevState.humidity.value,
                        staticText: prevState.humidity.staticText,
                    },
                    temperatureC_DHT22: {
                        value: `Temperature: ${formatFloat(data.temperatureC_DHT22)} &#176;C`,
                        previousValue: prevState.temperatureC_DHT22.value,
                        staticText: prevState.temperatureC_DHT22.staticText,
                    },
                    temperatureC_DS18B20: {
                        value: `Temperature: ${formatFloat(data.temperatureC_DS18B20)} &#176;C`,
                        previousValue: prevState.temperatureC_DS18B20.value,
                        staticText: prevState.temperatureC_DS18B20.staticText,
                    },
                    tdsValue: {
                        value: `TDS: ${formatFloat(data.tdsValue)} ppm`,
                        previousValue: prevState.tdsValue.value,
                        staticText: prevState.tdsValue.staticText,
                    },
                    pHValue: {
                        value: `pH: ${data.pHValue}`,
                        previousValue: prevState.pHValue.value,
                        staticText: prevState.pHValue.staticText,
                    },
                    flowRate: {
                        value: `Flow Rate: ${data.flowRate} L/min`,
                        previousValue: prevState.flowRate.value,
                        staticText: prevState.flowRate.staticText,
                    },
                    pressure: {
                        value: `Pressure: ${data.pressure} bar`,
                        previousValue: prevState.pressure.value,
                        staticText: prevState.pressure.staticText,
                    },
                    luminosity: {
                        value: `Luminosity: 97.36 Lux`,
                        previousValue: prevState.luminosity.value,
                        staticText: prevState.luminosity.staticText,
                    },
                    ammoniaValue: {
                        value: `Ammonia: ${data.ammoniaValue} ppm`,
                        previousValue: prevState.ammoniaValue.value,
                        staticText: prevState.ammoniaValue.staticText,
                    },
                    dissolvedOxygen: {
                        value: `Dissolved Oxygen: ${data.dissolvedOxygen} mg/L`,
                        previousValue: prevState.dissolvedOxygen.value,
                        staticText: prevState.dissolvedOxygen.staticText,
                    },
                }));

            } catch (error) {
                console.error('Error fetching sensor data:', error.message);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 2000);
        return () => clearInterval(interval);
    }, []);



    // Function to format float values to 2 decimal places
    const formatFloat = (value) => {
        return parseFloat(value).toFixed(2);
    };

    return (
        <div className="sensor-data-display">
            <SensorCard
                sensorName="Water Level"
                sensorValue={sensorData.floatSwitchState.value}
                currentValue={sensorData.floatSwitchState.value}
                previousValue={sensorData.floatSwitchState.previousValue}
                staticText={sensorData.floatSwitchState.staticText}
            />
            <SensorCard
                sensorName="Ambient Temperature"
                sensorValue={sensorData.temperatureC_DHT22.value}
                currentValue={sensorData.temperatureC_DHT22.value}
                previousValue={sensorData.temperatureC_DHT22.previousValue}
                staticText={sensorData.temperatureC_DHT22.staticText}
            />
            <SensorCard
                sensorName="Ambient Humidity"
                sensorValue={sensorData.humidity.value}
                currentValue={sensorData.humidity.value}
                previousValue={sensorData.humidity.previousValue}
                staticText={sensorData.humidity.staticText}
            />
            <SensorCard
                sensorName="Water Temperature"
                sensorValue={sensorData.temperatureC_DS18B20.value}
                currentValue={sensorData.temperatureC_DS18B20.value}
                previousValue={sensorData.temperatureC_DS18B20.previousValue}
                staticText={sensorData.temperatureC_DS18B20.staticText}
            />
            <SensorCard
                sensorName="Total Dissolved Solids"
                sensorValue={sensorData.tdsValue.value}
                currentValue={sensorData.tdsValue.value}
                previousValue={sensorData.tdsValue.previousValue}
                staticText={sensorData.tdsValue.staticText}
            />
            <SensorCard
                sensorName="Light Sensor"
                sensorValue={sensorData.luminosity.value}
                currentValue={sensorData.luminosity.value}
                previousValue={sensorData.luminosity.previousValue}
                staticText={sensorData.luminosity.staticText}
            />
        </div>
    );
};

export default SensorDataDisplay;
